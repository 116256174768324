@charset "UTF-8";

:root {

	/* Font Family */
	--global--font-primary: var(--font-headings, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif);
	--global--font-secondary: var(--font-base, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif);

	/* Font Size */
	--global--font-size-base: 1.25rem;
	--global--font-size-xs: 1rem;
	--global--font-size-sm: 1.125rem;
	--global--font-size-md: 1.25rem;
	--global--font-size-lg: 1.5rem;
	--global--font-size-xl: 2.25rem;
	--global--font-size-xxl: 4rem;
	--global--font-size-xxxl: 5rem;
	--global--font-size-page-title: var(--global--font-size-xxl);
	--global--letter-spacing: normal;

	/* Line Height */
	--global--line-height-body: 1.7;
	--global--line-height-heading: 1.3;
	--global--line-height-page-title: 1.1;

	/* Headings */
	--heading--font-family: var(--global--font-primary);
	--heading--font-size-h6: var(--global--font-size-xs);
	--heading--font-size-h5: var(--global--font-size-sm);
	--heading--font-size-h4: var(--global--font-size-lg);
	--heading--font-size-h3: calc(1.25 * var(--global--font-size-lg));
	--heading--font-size-h2: var(--global--font-size-xl);
	--heading--font-size-h1: var(--global--font-size-page-title);
	--heading--letter-spacing-h6: 0.05em;
	--heading--letter-spacing-h5: 0.05em;
	--heading--letter-spacing-h4: var(--global--letter-spacing);
	--heading--letter-spacing-h3: var(--global--letter-spacing);
	--heading--letter-spacing-h2: var(--global--letter-spacing);
	--heading--letter-spacing-h1: var(--global--letter-spacing);
	--heading--line-height-h6: var(--global--line-height-heading);
	--heading--line-height-h5: var(--global--line-height-heading);
	--heading--line-height-h4: var(--global--line-height-heading);
	--heading--line-height-h3: var(--global--line-height-heading);
	--heading--line-height-h2: var(--global--line-height-heading);
	--heading--line-height-h1: var(--global--line-height-page-title);
	--heading--font-weight: normal;
	--heading--font-weight-page-title: 300;
	--heading--font-weight-strong: 600;

	/* Block: Latest posts */
	--latest-posts--title-font-family: var(--heading--font-family);
	--latest-posts--title-font-size: var(--heading--font-size-h3);
	--latest-posts--description-font-family: var(--global--font-secondary);
	--latest-posts--description-font-size: var(--global--font-size-sm);
	--list--font-family: var(--global--font-secondary);
	--definition-term--font-family: var(--global--font-primary);

	/* Colors */
	--global--color-black: #000;
	--global--color-dark-gray: #28303d;
	--global--color-gray: #39414d;
	--global--color-light-gray: #f0f0f0;
	--global--color-green: #d1e4dd;
	--global--color-blue: #d1dfe4;
	--global--color-purple: #d1d1e4;
	--global--color-red: #e4d1d1;
	--global--color-orange: #e4dad1;
	--global--color-yellow: #eeeadd;
	--global--color-white: #fff;
	--global--color-white-50: rgba(255, 255, 255, 0.5);
	--global--color-white-90: rgba(255, 255, 255, 0.9);
	--global--color-primary: var(--global--color-dark-gray); /* Body text color, site title, footer text color. */
	--global--color-secondary: var(--global--color-gray); /* Headings */
	--global--color-primary-hover: var(--global--color-primary);
	--global--color-background: var(--global--color-green); /* Mint, default body background */
	--global--color-border: var(--global--color-primary); /* Used for borders (separators) */

	/* Spacing */
	--global--spacing-unit: 20px;
	--global--spacing-measure: unset;
	--global--spacing-horizontal: 25px;
	--global--spacing-vertical: 30px;

	/* Elevation */
	--global--elevation: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);

	/* Forms */
	--form--font-family: var(--global--font-secondary);
	--form--font-size: var(--global--font-size-sm);
	--form--line-height: var(--global--line-height-body);
	--form--color-text: var(--global--color-dark-gray);
	--form--color-ranged: var(--global--color-secondary);
	--form--label-weight: 500;
	--form--border-color: var(--global--color-secondary);
	--form--border-width: 3px;
	--form--border-radius: 0;
	--form--spacing-unit: calc(0.5 * var(--global--spacing-unit));

	/* Cover block */
	--cover--height: calc(15 * var(--global--spacing-vertical));
	--cover--color-foreground: var(--global--color-white);
	--cover--color-background: var(--global--color-black);

	/* Buttons */
	--button--color-text: var(--global--color-background);
	--button--color-text-hover: var(--global--color-secondary);
	--button--color-text-active: var(--global--color-secondary);
	--button--color-background: var(--global--color-secondary);
	--button--color-background-active: var(--global--color-background);
	--button--font-family: var(--global--font-primary);
	--button--font-size: var(--global--font-size-base);
	--button--font-weight: 500;
	--button--line-height: 1.5;
	--button--border-width: 3px;
	--button--border-radius: 0;
	--button--padding-vertical: 15px;
	--button--padding-horizontal: calc(2 * var(--button--padding-vertical));

	/* entry */
	--entry-header--color: var(--global--color-primary);
	--entry-header--color-link: currentColor;
	--entry-header--color-hover: var(--global--color-primary-hover);
	--entry-header--color-focus: var(--global--color-secondary);
	--entry-header--font-size: var(--heading--font-size-h2);
	--entry-content--font-family: var(--global--font-secondary);
	--entry-author-bio--font-family: var(--heading--font-family);
	--entry-author-bio--font-size: var(--heading--font-size-h4);

	/* Header */
	--branding--color-text: var(--global--color-primary);
	--branding--color-link: var(--global--color-primary);
	--branding--color-link-hover: var(--global--color-secondary);
	--branding--title--font-family: var(--global--font-primary);
	--branding--title--font-size: var(--global--font-size-lg);
	--branding--title--font-size-mobile: var(--heading--font-size-h4);
	--branding--title--font-weight: normal;
	--branding--title--text-transform: uppercase;
	--branding--description--font-family: var(--global--font-secondary);
	--branding--description--font-size: var(--global--font-size-sm);
	--branding--description--font-family: var(--global--font-secondary);
	--branding--logo--max-width: 300px;
	--branding--logo--max-height: 100px;
	--branding--logo--max-width-mobile: 96px;
	--branding--logo--max-height-mobile: 96px;

	/* Main navigation */
	--primary-nav--font-family: var(--global--font-secondary);
	--primary-nav--font-family-mobile: var(--global--font-primary);
	--primary-nav--font-size: var(--global--font-size-md);
	--primary-nav--font-size-sub-menu: var(--global--font-size-xs);
	--primary-nav--font-size-mobile: var(--global--font-size-sm);
	--primary-nav--font-size-sub-menu-mobile: var(--global--font-size-sm);
	--primary-nav--font-size-button: var(--global--font-size-xs);
	--primary-nav--font-style: normal;
	--primary-nav--font-style-sub-menu-mobile: normal;
	--primary-nav--font-weight: normal;
	--primary-nav--font-weight-button: 500;
	--primary-nav--color-link: var(--global--color-primary);
	--primary-nav--color-link-hover: var(--global--color-primary-hover);
	--primary-nav--color-text: var(--global--color-primary);
	--primary-nav--padding: calc(0.66 * var(--global--spacing-unit));
	--primary-nav--border-color: var(--global--color-primary);

	/* Pagination */
	--pagination--color-text: var(--global--color-primary);
	--pagination--color-link-hover: var(--global--color-primary-hover);
	--pagination--font-family: var(--global--font-secondary);
	--pagination--font-size: var(--global--font-size-lg);
	--pagination--font-weight: normal;
	--pagination--font-weight-strong: 600;

	/* Footer */
	--footer--color-text: var(--global--color-primary);
	--footer--color-link: var(--global--color-primary);
	--footer--color-link-hover: var(--global--color-primary-hover);
	--footer--font-family: var(--global--font-primary);
	--footer--font-size: var(--global--font-size-sm);

	/* Block: Pull quote */
	--pullquote--font-family: var(--global--font-primary);
	--pullquote--font-size: var(--heading--font-size-h3);
	--pullquote--font-style: normal;
	--pullquote--letter-spacing: var(--heading--letter-spacing-h4);
	--pullquote--line-height: var(--global--line-height-heading);
	--pullquote--border-width: 3px;
	--pullquote--border-color: var(--global--color-primary);
	--pullquote--color-foreground: var(--global--color-primary);
	--pullquote--color-background: var(--global--color-background);
	--quote--font-family: var(--global--font-secondary);
	--quote--font-size: var(--global--font-size-md);
	--quote--font-size-large: var(--global--font-size-xl);
	--quote--font-style: normal;
	--quote--font-weight: 700;
	--quote--font-weight-strong: bolder;
	--quote--font-style-large: normal;
	--quote--font-style-cite: normal;
	--quote--line-height: var(--global--line-height-body);
	--quote--line-height-large: 1.35;
	--separator--border-color: var(--global--color-border);
	--separator--height: 1px;

	/* Block: Table */
	--table--stripes-border-color: var(--global--color-light-gray);
	--table--stripes-background-color: var(--global--color-light-gray);
	--table--has-background-text-color: var(--global--color-dark-gray);

	/* Widgets */
	--widget--line-height-list: 1.9;
	--widget--line-height-title: 1.4;
	--widget--font-weight-title: 700;
	--widget--spacing-menu: calc(0.66 * var(--global--spacing-unit));

	/* Admin-bar height */
	--global--admin-bar--height: 0px;
}


/**
 * Extends
 */
.post-thumbnail,
.entry-content .wp-audio-shortcode,
.entry-content > *:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator),
*[class*=inner-container] > *:not(.entry-content):not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator),
.default-max-width {
	max-width: var(--responsive--aligndefault-width);
	margin-left: auto;
	margin-right: auto;
}

.widget-area,
.pagination,
.comments-pagination,
.post-navigation,
.site-footer,
.site-header,
.alignwide,
.wide-max-width {
	max-width: var(--responsive--alignwide-width);
	margin-left: auto;
	margin-right: auto;
}

.alignfull,
.wp-block-group .wp-block-group__inner-container > *.alignfull,
.full-max-width {
	max-width: var(--responsive--alignfull-width);
	width: var(--responsive--alignfull-width);
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (min-width: 482px) {

	.alignfull,
	.full-max-width {
		max-width: var(--responsive--alignfull-width);
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}
}

.entry-header .post-thumbnail,
.singular .post-thumbnail,
.alignfull [class*=inner-container] > .alignwide,
.alignwide [class*=inner-container] > .alignwide {
	margin-left: auto;
	margin-right: auto;
	width: var(--responsive--alignwide-width);
	max-width: var(--responsive--alignfull-width);
}

@media only screen and (min-width: 482px) {

	.entry-content > .alignleft {

		/*rtl:ignore*/
		margin-left: var(--responsive--alignleft-margin);

		/*rtl:ignore*/
		margin-right: var(--global--spacing-horizontal);
	}
}
@media only screen and (min-width: 482px) {

	.entry-content > .alignright {

		/*rtl:ignore*/
		margin-left: var(--global--spacing-horizontal);

		/*rtl:ignore*/
		margin-right: var(--responsive--alignright-margin);
	}
}

/**
 * Site Structure
 *
 * - Set vertical margins and responsive widths on
 *   top-level wrappers and content wrappers
 * - `--global--width-content` is a responsive variable
 * - See: globals/_global-width-responsive.scss
 */

/**
 * Top Level Wrappers (header, main, footer)
 * - Set vertical padding and horizontal margins
 */
.site-header,
.site-main,
.widget-area,
.site-footer {
	padding-top: var(--global--spacing-vertical);
	padding-bottom: var(--global--spacing-vertical);
	margin-left: auto;
	margin-right: auto;
}

.site-header {
	padding-top: calc(0.75 * var(--global--spacing-vertical));
	padding-bottom: calc(2 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.site-header {
		padding-bottom: calc(3 * var(--global--spacing-vertical));
	}
}

/**
 * Site-main children wrappers
 * - Add double vertical margins here for clearer hierarchy
 */
.site-main > * {
	margin-top: calc(3 * var(--global--spacing-vertical));
	margin-bottom: calc(3 * var(--global--spacing-vertical));
}

.site-main > *:first-child {
	margin-top: 0;
}

.site-main > *:last-child {
	margin-bottom: 0;
}

/**
 * Set the default maximum responsive content-width
 */

/**
 * Set the wide maximum responsive content-width
 */

/**
 * Set the full maximum responsive content-width
 */

/*
 * Block & non-gutenberg content wrappers
 * - Set margins
 */
.entry-header,
.post-thumbnail,
.entry-content,
.entry-footer,
.author-bio {
	margin-top: var(--global--spacing-vertical);
	margin-right: auto;
	margin-bottom: var(--global--spacing-vertical);
	margin-left: auto;
}

/*
 * Block & non-gutenberg content wrapper children
 * - Sets spacing-vertical margin logic
 */
.site-main > article > *,
.site-main > .not-found > *,
.entry-content > *,
[class*=inner-container] > *,
.wp-block-template-part > *,
.wp-block-post-template :where(li > *) {
	margin-top: calc(0.666 * var(--global--spacing-vertical));
	margin-bottom: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.site-main > article > *,
	.site-main > .not-found > *,
	.entry-content > *,
	[class*=inner-container] > *,
	.wp-block-template-part > *,
	.wp-block-post-template :where(li > *) {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

.site-main > article > *:first-child,
.site-main > .not-found > *:first-child,
.entry-content > *:first-child,
[class*=inner-container] > *:first-child,
.wp-block-template-part > *:first-child,
.wp-block-post-template :where(li > *):first-child {
	margin-top: 0;
}

.site-main > article > *:last-child,
.site-main > .not-found > *:last-child,
.entry-content > *:last-child,
[class*=inner-container] > *:last-child,
.wp-block-template-part > *:last-child,
.wp-block-post-template :where(li > *):last-child {
	margin-bottom: 0;
}

.site-footer > *,
.widget-area > * {
	margin-top: calc(0.666 * var(--global--spacing-vertical));
	margin-bottom: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.site-footer > *,
	.widget-area > * {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

/*
 * Block & non-gutenberg content wrapper children
 * - Sets spacing-unit margins
 */
.entry-header > *,
.post-thumbnail > *,
.page-content > *,
.comment-content > *,
.widget > * {
	margin-top: var(--global--spacing-unit);
	margin-bottom: var(--global--spacing-unit);
}

.entry-header > *:first-child,
.post-thumbnail > *:first-child,
.page-content > *:first-child,
.comment-content > *:first-child,
.widget > *:first-child {
	margin-top: 0;
}

.entry-header > *:last-child,
.post-thumbnail > *:last-child,
.page-content > *:last-child,
.comment-content > *:last-child,
.widget > *:last-child {
	margin-bottom: 0;
}

/*
 * .entry-content children specific controls
 * - Adds special margin overrides for alignment utility classes
 */

.entry-content > *.alignleft,
.entry-content > *.alignright,
.entry-content > *.alignleft:first-child + *,
.entry-content > *.alignright:first-child + *,
.entry-content > *.alignfull.has-background {
	margin-top: 0;
}

.entry-content > *:last-child,
.entry-content > *.alignfull.has-background {
	margin-bottom: 0;
}

.entry-content > *.alignfull + .alignleft,
.entry-content > *.alignfull + .alignright {
	margin-top: var(--global--spacing-vertical);
}

/* Media captions */
figcaption,
.wp-caption,
.wp-caption-text,
.wp-block-embed figcaption {
	color: currentColor;
	font-size: var(--global--font-size-xs);
	line-height: var(--global--line-height-body);
	margin-top: calc(0.5 * var(--global--spacing-unit));
	margin-bottom: var(--global--spacing-unit);
	text-align: center;
}

.alignleft figcaption,
.alignright figcaption,
.alignleft .wp-caption,
.alignright .wp-caption,
.alignleft .wp-caption-text,
.alignright .wp-caption-text,
.alignleft .wp-block-embed figcaption,
.alignright .wp-block-embed figcaption {
	margin-bottom: 0;
}

/* WP Smiley */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}


.site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) {

	/* Only visible in Windows High Contrast mode */
	outline: 2px solid transparent;
	text-decoration: underline 1px dotted currentColor;
	text-decoration-skip-ink: none;
	background: rgba(255, 255, 255, 0.9);
}

.is-dark-theme .site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) {
	background: var(--global--color-black);
	color: var(--global--color-white);
	text-decoration: none;
}

.is-dark-theme .site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) .meta-nav {
	color: var(--wp--style--color--link, var(--global--color-white));
}

.has-background-white .site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) {
	background: rgba(0, 0, 0, 0.9);
	color: var(--wp--style--color--link, var(--global--color-white));
}

.has-background-white .site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) .meta-nav {
	color: var(--wp--style--color--link, var(--global--color-white));
}

.site a:focus:not(.wp-block-button__link):not(.wp-block-file__button).skip-link {

	/* Only visible in Windows High Contrast mode */
	outline: 2px solid transparent;
	outline-offset: -2px;
}

.site a:focus:not(.wp-block-button__link):not(.wp-block-file__button).skip-link:focus {
	color: #21759b;
	background-color: #f1f1f1;
}

.site a:focus:not(.wp-block-button__link):not(.wp-block-file__button).custom-logo-link {
	background: none;
}

.site a:focus:not(.wp-block-button__link):not(.wp-block-file__button) img {
	outline: 2px dotted var(--wp--style--color--link, var(--global--color-primary));
}

.has-background .has-link-color a,
.has-background.has-link-color a {
	color: var(--wp--style--color--link, var(--global--color-primary));
}

/* Category 05 is all about adjusting the default block styles to the given layout. I only added three blocks as examples. */
.wp-block-audio audio:focus {
	outline-offset: 5px;
	outline: 2px solid var(--global--color-primary);
}

/**
 * Block Options
 */
.wp-block-button:not(.is-style-outline) .wp-block-button__link:not(:hover):not(:active):not(.has-text-color) {
	color: var(--global--color-background);
}

.has-background .wp-block-button:not(.is-style-outline) .wp-block-button__link:not(:hover):not(:active):not(.has-text-color) {
	color: var(--local--color-background, var(--global--color-background));
}

.has-background .wp-block-button:not(.is-style-outline) .wp-block-button__link:not(:hover):not(:active):not(.has-text-color).has-background {
	color: var(--global--color-primary);
}

.wp-block-button:not(.is-style-outline) .wp-block-button__link:not(:hover):not(:active):not(.has-background) {
	background-color: var(--global--color-primary);
}

.has-background .wp-block-button:not(.is-style-outline) .wp-block-button__link:not(:hover):not(:active):not(.has-background) {
	background-color: var(--local--color-primary, var(--global--color-primary));
}

.wp-block-button:not(.is-style-outline) .wp-block-button__link:hover,
.wp-block-button:not(.is-style-outline) .wp-block-button__link:active {
	border-color: currentColor !important;
	background-color: transparent !important;
	color: inherit !important;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active):not(.has-text-color),
.wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active):not(.has-background),
.wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active).has-background {
	border-color: currentColor;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active):not(.has-text-color) {
	color: var(--global--color-primary);
}

.has-background .wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active):not(.has-text-color) {
	color: var(--local--color-primary, var(--global--color-primary));
}

.has-background .wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active).has-background:not(.has-text-color) {
	color: inherit;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(:hover):not(:active):not(.has-background) {
	background-color: transparent;
}

.wp-block-button.is-style-outline .wp-block-button__link:hover,
.wp-block-button.is-style-outline .wp-block-button__link:active {
	border-color: transparent !important;
	background-color: var(--global--color-primary) !important;
	color: var(--global--color-background) !important;
}

.has-background .wp-block-button.is-style-outline .wp-block-button__link:hover,
.has-background .wp-block-button.is-style-outline .wp-block-button__link:active {
	background-color: var(--local--color-primary, var(--global--color-primary)) !important;
	color: var(--local--color-background, var(--global--color-background)) !important;
}

.has-text-color .wp-block-button.is-style-outline .wp-block-button__link:hover,
.has-text-color .wp-block-button.is-style-outline .wp-block-button__link:active {
	color: var(--local--color-background, var(--global--color-background)) !important;
}

.wp-block-button .is-style-squared .wp-block-button__link {
	border-radius: 0;
}

.is-style-outline .wp-block-button__link[style*=radius]:focus,
.wp-block-button a.wp-block-button__link[style*=radius]:focus {
	outline-offset: 2px;
	outline: 2px dotted var(--button--color-background);
}

.wp-block-code {
	border-color: var(--global--color-border);
	border-radius: 0;
	border-style: solid;
	border-width: 0.1rem;
	padding: var(--global--spacing-unit);
}

.wp-block-code code {
	color: var(--global--color-primary);
	white-space: pre;
	overflow-x: auto;
	display: block;
}

.wp-block-columns:not(.alignwide):not(.alignfull) {
	clear: both;
}

.wp-block-columns .wp-block-column > * {
	margin-top: calc(0.66 * var(--global--spacing-vertical));
	margin-bottom: calc(0.66 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-columns .wp-block-column > * {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

.wp-block-columns .wp-block-column > *:first-child {
	margin-top: 0;
}

.wp-block-columns .wp-block-column > *:last-child {
	margin-bottom: 0;
}

.wp-block-columns .wp-block-column:last-child {
	margin-bottom: 0;
}

.wp-block-columns .wp-block-column:not(:last-child) {
	margin-bottom: calc(0.66 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-columns .wp-block-column:not(:last-child) {
		margin-bottom: var(--global--spacing-vertical);
	}
}
@media only screen and (min-width: 822px) {

	.wp-block-columns .wp-block-column:not(:last-child) {
		margin-bottom: 0;
	}
}

.wp-block-columns.is-style-twentytwentyone-columns-overlap {
	justify-content: space-around;
}
@media only screen and (min-width: 652px) {

	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) {
		margin-left: calc(-2 * var(--global--spacing-horizontal));
		margin-top: calc(2.5 * var(--global--spacing-horizontal));
		z-index: 2;
	}

	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > p:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h1:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h2:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h3:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h4:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h5:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > h6:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > ul:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > ol:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > pre:not(.has-background) {
		background-color: var(--global--color-background);
		padding: var(--global--spacing-unit);
	}

	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > ul:not(.has-background),
	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n) > ol:not(.has-background) {
		padding-left: calc(2 * var(--global--spacing-horizontal));
	}

	.wp-block-columns.is-style-twentytwentyone-columns-overlap .wp-block-column:nth-child(2n).is-vertically-aligned-center {
		margin-top: 0;
	}
}

.wp-block-columns.alignfull .wp-block-column p:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h1:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h2:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h3:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h4:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h5:not(.has-background),
.wp-block-columns.alignfull .wp-block-column h6:not(.has-background) {
	padding-left: var(--global--spacing-unit);
	padding-right: var(--global--spacing-unit);
}

.wp-block-cover,
.wp-block-cover-image {
	background-color: var(--cover--color-background);
	min-height: var(--cover--height);
	margin-top: inherit;
	margin-bottom: inherit;

	/* default & custom background-color */

	/* Treating H2 separately to account for legacy /core styles */

	/* Block Styles */

	/* The background color class is used just for the overlay, and does not need to be applied to the inner container. */
}

.wp-block-cover:not(.alignwide):not(.alignfull),
.wp-block-cover-image:not(.alignwide):not(.alignfull) {
	clear: both;
}

.wp-block-cover.alignfull,
.wp-block-cover-image.alignfull {
	margin-top: 0;
	margin-bottom: 0;
}

.wp-block-cover .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover-image .wp-block-cover-text {
	color: currentColor;
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-cover .wp-block-cover__inner-container a:not(.wp-block-button__link):not(.wp-block-file__button),
.wp-block-cover .wp-block-cover-image-text a:not(.wp-block-button__link):not(.wp-block-file__button),
.wp-block-cover .wp-block-cover-text a:not(.wp-block-button__link):not(.wp-block-file__button),
.wp-block-cover-image .wp-block-cover__inner-container a:not(.wp-block-button__link):not(.wp-block-file__button),
.wp-block-cover-image .wp-block-cover-image-text a:not(.wp-block-button__link):not(.wp-block-file__button),
.wp-block-cover-image .wp-block-cover-text a:not(.wp-block-button__link):not(.wp-block-file__button) {
	color: currentColor;
}

.wp-block-cover .wp-block-cover__inner-container .has-link-color a,
.wp-block-cover .wp-block-cover-image-text .has-link-color a,
.wp-block-cover .wp-block-cover-text .has-link-color a,
.wp-block-cover-image .wp-block-cover__inner-container .has-link-color a,
.wp-block-cover-image .wp-block-cover-image-text .has-link-color a,
.wp-block-cover-image .wp-block-cover-text .has-link-color a {
	color: var(--wp--style--color--link, var(--global--color-primary));
}

.wp-block-cover:not([class*=background-color]) .wp-block-cover__inner-container,
.wp-block-cover:not([class*=background-color]) .wp-block-cover-image-text,
.wp-block-cover:not([class*=background-color]) .wp-block-cover-text,
.wp-block-cover-image:not([class*=background-color]) .wp-block-cover__inner-container,
.wp-block-cover-image:not([class*=background-color]) .wp-block-cover-image-text,
.wp-block-cover-image:not([class*=background-color]) .wp-block-cover-text {
	color: var(--cover--color-foreground);
}

.wp-block-cover h2,
.wp-block-cover-image h2 {
	font-size: var(--heading--font-size-h2);
	letter-spacing: var(--heading--letter-spacing-h2);
	line-height: var(--heading--line-height-h2);
	max-width: inherit;
	text-align: inherit;
	padding: 0;
}

.wp-block-cover h2.has-text-align-left,
.wp-block-cover-image h2.has-text-align-left {
	text-align: left;
}

.wp-block-cover h2.has-text-align-center,
.wp-block-cover-image h2.has-text-align-center {
	text-align: center;
}

.wp-block-cover h2.has-text-align-right,
.wp-block-cover-image h2.has-text-align-right {
	text-align: right;
}

.wp-block-cover .wp-block-cover__inner-container,
.wp-block-cover-image .wp-block-cover__inner-container {
	width: calc(100% - 2 * var(--global--spacing-vertical));
}

.wp-block-cover .wp-block-cover__inner-container > *,
.wp-block-cover-image .wp-block-cover__inner-container > * {
	margin-top: calc(0.666 * var(--global--spacing-vertical));
	margin-bottom: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-cover .wp-block-cover__inner-container > *,
	.wp-block-cover-image .wp-block-cover__inner-container > * {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

.wp-block-cover .wp-block-cover__inner-container > *:first-child,
.wp-block-cover-image .wp-block-cover__inner-container > *:first-child {
	margin-top: 0;
}

.wp-block-cover .wp-block-cover__inner-container > *:last-child,
.wp-block-cover-image .wp-block-cover__inner-container > *:last-child {
	margin-bottom: 0;
}

.wp-block-cover.alignleft,
.wp-block-cover.alignright,
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright {
	margin-top: 0;
}

.wp-block-cover.alignleft > *,
.wp-block-cover.alignright > *,
.wp-block-cover-image.alignleft > *,
.wp-block-cover-image.alignright > * {
	margin-top: calc(2 * var(--global--spacing-vertical));
	margin-bottom: calc(2 * var(--global--spacing-vertical));
	padding-left: var(--global--spacing-horizontal);
	padding-right: var(--global--spacing-horizontal);
	width: 100%;
}

.wp-block-cover.has-left-content,
.wp-block-cover.has-right-content,
.wp-block-cover-image.has-left-content,
.wp-block-cover-image.has-right-content {
	justify-content: center;
}

.wp-block-cover.is-style-twentytwentyone-border,
.wp-block-cover-image.is-style-twentytwentyone-border {
	border: calc(3 * var(--separator--height)) solid var(--global--color-border);
}

.wp-block-cover[class*=-background-color][class] .wp-block-cover__inner-container,
.wp-block-cover-image[class*=-background-color][class] .wp-block-cover__inner-container {
	background-color: unset;
}

.wp-block-file a.wp-block-file__button:active,
.wp-block-file a.wp-block-file__button:focus,
.wp-block-file a.wp-block-file__button:hover {
	opacity: inherit;
}

.wp-block-file a.wp-block-file__button {
	display: inline-block;
}

.wp-block-gallery {
	margin: 0 auto;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
	width: calc((100% - var(--global--spacing-unit)) / 2);
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
	margin: 0;
	color: var(--global--color-white);
	font-size: var(--global--font-size-xs);
}

.wp-block-gallery .blocks-gallery-image figcaption a,
.wp-block-gallery .blocks-gallery-item figcaption a {
	color: var(--global--color-white);
}

.wp-block-gallery .blocks-gallery-image figcaption a:focus,
.wp-block-gallery .blocks-gallery-item figcaption a:focus {
	background-color: transparent;
	outline: 2px solid var(--wp--style--color--link, var(--global--color-primary));
	text-decoration: none;
}

.wp-block-gallery .blocks-gallery-image a:focus img,
.wp-block-gallery .blocks-gallery-item a:focus img {
	outline-offset: 2px;
}

.wp-block-group {
	display: block;
	clear: both;
	display: flow-root;
}

.wp-block-group:before,
.wp-block-group:after {
	content: "";
	display: block;
	clear: both;
}

.wp-block-group .wp-block-group__inner-container {
	margin-left: auto;
	margin-right: auto;
}

.wp-block-group .wp-block-group__inner-container > * {
	margin-top: calc(0.666 * var(--global--spacing-vertical));
	margin-bottom: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-group .wp-block-group__inner-container > * {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

.wp-block-group .wp-block-group__inner-container > *:first-child {
	margin-top: 0;
}

.wp-block-group .wp-block-group__inner-container > *:last-child {
	margin-bottom: 0;
}

.wp-block-group.has-background {
	padding: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-group.has-background {
		padding: var(--global--spacing-vertical);
	}
}

.wp-block-group.is-style-twentytwentyone-border {
	border: calc(3 * var(--separator--height)) solid var(--global--color-border);
	padding: var(--global--spacing-vertical);
}

.wp-block-group.has-background .wp-block-group__inner-container > .alignfull,
.wp-block-group.has-background .wp-block-group__inner-container > hr.wp-block-separator:not(.is-style-dots):not(.alignwide).alignfull,
.wp-block-group.is-style-twentytwentyone-border .wp-block-group__inner-container > .alignfull,
.wp-block-group.is-style-twentytwentyone-border .wp-block-group__inner-container > hr.wp-block-separator:not(.is-style-dots):not(.alignwide).alignfull {
	max-width: calc(var(--responsive--alignfull-width) + 2 * var(--global--spacing-vertical));
	width: calc(var(--responsive--alignfull-width) + 2 * var(--global--spacing-vertical));
	margin-left: calc(-1 * var(--global--spacing-vertical));
}

.wp-block-image {
	text-align: center;
}

.wp-block-image figcaption {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
	line-height: var(--global--line-height-body);
	margin-top: calc(0.5 * var(--global--spacing-unit));
	margin-bottom: var(--global--spacing-unit);
	text-align: center;
}

.wp-block-image .alignright {
	margin-left: var(--global--spacing-horizontal);
}

.wp-block-image .alignleft {
	margin-right: var(--global--spacing-horizontal);
}

.wp-block-image a:focus img {
	outline-offset: 2px;
}

.entry-content > *[class=wp-block-image],
.entry-content [class*=inner-container] > *[class=wp-block-image] {
	margin-top: 0;
	margin-bottom: 0;
}

.entry-content > *[class=wp-block-image] + *,
.entry-content [class*=inner-container] > *[class=wp-block-image] + * {
	margin-top: 0;
}

.wp-block-image.is-style-twentytwentyone-border img,
.wp-block-image.is-style-twentytwentyone-image-frame img {
	border: calc(3 * var(--separator--height)) solid var(--global--color-border);
}

.wp-block-image.is-style-twentytwentyone-image-frame img {
	padding: var(--global--spacing-unit);
}

@media only screen and (min-width: 482px) {

	.entry-content > .wp-block-image > .alignleft,
	.entry-content > .wp-block-image > .alignright {
		max-width: 50%;
	}
}
@media only screen and (max-width: 481px) {

	.entry-content > .wp-block-image > .alignleft,
	.entry-content > .wp-block-image > .alignright {
		margin-left: 0;
		margin-right: 0;
	}
}

.wp-block-latest-comments {
	padding-left: 0;
}

.wp-block-latest-comments .wp-block-latest-comments__comment {
	font-size: var(--global--font-size-sm);
	line-height: var(--global--line-height-body);

	/* Vertical margins logic */
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-latest-comments .wp-block-latest-comments__comment:first-child {
	margin-top: 0;
}

.wp-block-latest-comments .wp-block-latest-comments__comment:last-child {
	margin-bottom: 0;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta {
	font-family: var(--heading--font-family);
}

.wp-block-latest-comments .wp-block-latest-comments__comment-date {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-sm);
}

.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
	font-size: var(--global--font-size-sm);
	line-height: var(--global--line-height-body);
	margin: 0;
}

.wp-block-latest-posts {
	padding-left: 0;
}

.wp-block-latest-posts:not(.is-grid) > li {
	margin-top: calc(1.666 * var(--global--spacing-vertical));
	margin-bottom: calc(1.666 * var(--global--spacing-vertical));
}

.wp-block-latest-posts:not(.is-grid) > li:first-child {
	margin-top: 0;
}

.wp-block-latest-posts:not(.is-grid) > li:last-child {
	margin-bottom: 0;
}

.widget-area .wp-block-latest-posts:not(.is-grid) > li {
	margin-top: 0;
	margin-bottom: 0;
}

.wp-block-latest-posts.is-grid {
	word-wrap: break-word;
	word-break: break-word;
}

.wp-block-latest-posts.is-grid > li {
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-latest-posts.is-grid > li:last-child {
	margin-bottom: 0;
}

.wp-block-latest-posts.is-grid.columns-2 > li:nth-last-child(-n+2):nth-child(2n+1),
.wp-block-latest-posts.is-grid.columns-2 > li:nth-last-child(-n+2):nth-child(2n+1) ~ li,
.wp-block-latest-posts.is-grid.columns-3 > li:nth-last-child(-n+3):nth-child(3n+1),
.wp-block-latest-posts.is-grid.columns-3 > li:nth-last-child(-n+3):nth-child(3n+1) ~ li,
.wp-block-latest-posts.is-grid.columns-4 > li:nth-last-child(-n+4):nth-child(4n+1),
.wp-block-latest-posts.is-grid.columns-4 > li:nth-last-child(-n+4):nth-child(4n+1) ~ li,
.wp-block-latest-posts.is-grid.columns-5 > li:nth-last-child(-n+5):nth-child(5n+1),
.wp-block-latest-posts.is-grid.columns-5 > li:nth-last-child(-n+5):nth-child(5n+1) ~ li,
.wp-block-latest-posts.is-grid.columns-6 > li:nth-last-child(-n+6):nth-child(6n+1),
.wp-block-latest-posts.is-grid.columns-6 > li:nth-last-child(-n+6):nth-child(6n+1) ~ li {
	margin-bottom: 0;
}

.wp-block-latest-posts > li > * {
	margin-top: calc(0.333 * var(--global--spacing-vertical));
	margin-bottom: calc(0.333 * var(--global--spacing-vertical));
}

.wp-block-latest-posts > li > *:first-child {
	margin-top: 0;
}

.wp-block-latest-posts > li > *:last-child {
	margin-bottom: 0;
}

.wp-block-latest-posts > li > a {
	display: inline-block;
	font-family: var(--latest-posts--title-font-family);
	font-size: var(--latest-posts--title-font-size);
	font-weight: var(--heading--font-weight);
	line-height: var(--global--line-height-heading);
	margin-bottom: calc(0.333 * var(--global--spacing-vertical));
}

.widget-area .wp-block-latest-posts > li > a {
	font-size: var(--global--font-size-sm);
	margin-bottom: 0;
}

.wp-block-latest-posts .wp-block-latest-posts__post-author {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-md);
	line-height: var(--global--line-height-body);
}

.wp-block-latest-posts .wp-block-latest-posts__post-date {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
	line-height: var(--global--line-height-body);
}

[class*=inner-container] .wp-block-latest-posts .wp-block-latest-posts__post-date,
.has-background .wp-block-latest-posts .wp-block-latest-posts__post-date {
	color: currentColor;
}

.wp-block-latest-posts .wp-block-latest-posts__post-excerpt,
.wp-block-latest-posts .wp-block-latest-posts__post-full-content {
	font-family: var(--latest-posts--description-font-family);
	font-size: var(--latest-posts--description-font-size);
	line-height: var(--global--line-height-body);
	margin-top: calc(0.666 * var(--global--spacing-vertical));
}

.wp-block-latest-posts.alignfull {
	padding-left: var(--global--spacing-unit);
	padding-right: var(--global--spacing-unit);
}

.entry-content [class*=inner-container] .wp-block-latest-posts.alignfull,
.entry-content .has-background .wp-block-latest-posts.alignfull {
	padding-left: 0;
	padding-right: 0;
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers {
	border-top: calc(3 * var(--separator--height)) solid var(--global--color-border);
	border-bottom: calc(3 * var(--separator--height)) solid var(--global--color-border);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers:not(.is-grid) > li,
.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers > li {
	padding-bottom: var(--global--spacing-vertical);
	border-bottom: var(--separator--height) solid var(--global--color-border);
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers:not(.is-grid) > li:last-child,
.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers > li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid {
	box-shadow: inset 0 -1px 0 0 var(--global--color-border);
	border-bottom: calc(2 * var(--separator--height)) solid var(--global--color-border);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid li {
	margin: 0;
	padding-top: var(--global--spacing-vertical);
	padding-right: var(--global--spacing-horizontal);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid li:last-child {
	padding-bottom: var(--global--spacing-vertical);
}
@media screen and (min-width: 600px) {

	.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid.columns-2 li {
		width: calc(100% / 2);
	}

	.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid.columns-3 li {
		width: calc(100% / 3);
	}

	.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid.columns-4 li {
		width: calc(100% / 4);
	}

	.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid.columns-5 li {
		width: calc(100% / 5);
	}

	.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-dividers.is-grid.columns-6 li {
		width: calc(100% / 6);
	}
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-borders li {
	border: calc(3 * var(--separator--height)) solid var(--global--color-border);
	padding: var(--global--spacing-vertical) var(--global--spacing-horizontal);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-borders li:last-child {
	padding-bottom: var(--global--spacing-vertical);
}

.wp-block-latest-posts.is-style-twentytwentyone-latest-posts-borders:not(.is-grid) li {
	margin-top: var(--global--spacing-horizontal);
	margin-bottom: var(--global--spacing-horizontal);
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-item a {
	display: block;
}

.gallery-item a:focus img {
	outline-offset: -2px;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-caption {
	display: block;
}

figure.wp-caption a:focus img {
	outline-offset: 2px;
}


.wp-block-media-text.alignfull {
	margin-top: 0;
	margin-bottom: 0;
}

.wp-block-media-text a:focus img {
	outline-offset: -1px;
}

.wp-block-media-text .wp-block-media-text__content {
	padding: var(--global--spacing-horizontal);
}
@media only screen and (min-width: 592px) {

	.wp-block-media-text .wp-block-media-text__content {
		padding: var(--global--spacing-vertical);
	}
}

.wp-block-media-text .wp-block-media-text__content > * {
	margin-top: calc(0.666 * var(--global--spacing-vertical));
	margin-bottom: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-media-text .wp-block-media-text__content > * {
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);
	}
}

.wp-block-media-text .wp-block-media-text__content > *:first-child {
	margin-top: 0;
}

.wp-block-media-text .wp-block-media-text__content > *:last-child {
	margin-bottom: 0;
}
@media only screen and (min-width: 482px) {

	.wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
		padding-top: var(--global--spacing-vertical);
		padding-bottom: var(--global--spacing-vertical);
	}
}

.wp-block-media-text.is-style-twentytwentyone-border {
	border: calc(3 * var(--separator--height)) solid var(--global--color-border);
}

.wp-block-navigation .wp-block-navigation-link {
	padding: 0;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__content {
	padding: var(--primary-nav--padding);
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__label {
	font-family: var(--primary-nav--font-family);
	font-size: var(--primary-nav--font-size);
	font-weight: var(--primary-nav--font-weight);
}

.wp-block-navigation .wp-block-navigation-link__submenu-icon {
	padding: 0;
}

.wp-block-navigation > .wp-block-navigation__container .has-child .wp-block-navigation-link {
	display: inherit;
}

.wp-block-navigation > .wp-block-navigation__container .has-child .wp-block-navigation__container {
	border: none;
	left: 0;
	margin-left: var(--primary-nav--padding);
	min-width: max-content;
	opacity: 0;
	padding: 0;
	position: inherit;
	top: inherit;
}

.wp-block-navigation > .wp-block-navigation__container .has-child .wp-block-navigation__container .wp-block-navigation-link .wp-block-navigation-link__content {
	display: inline-block;
	padding: calc(0.5 * var(--primary-nav--padding)) var(--primary-nav--padding);
}

.wp-block-navigation > .wp-block-navigation__container .has-child .wp-block-navigation__container .wp-block-navigation-link__submenu-icon {
	display: none;
}

.wp-block-navigation > .wp-block-navigation__container .has-child:hover .wp-block-navigation__container,
.wp-block-navigation > .wp-block-navigation__container .has-child:focus-within .wp-block-navigation__container {
	display: block;
	opacity: 1;
	visibility: visible;
}

.wp-block-navigation > .wp-block-navigation__container > .has-child > .wp-block-navigation__container {
	background: var(--global--color-background);
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%;
	border: 1px solid var(--primary-nav--border-color);
}

.wp-block-navigation > .wp-block-navigation__container > .has-child > .wp-block-navigation__container:before,
.wp-block-navigation > .wp-block-navigation__container > .has-child > .wp-block-navigation__container:after {
	content: "";
	display: block;
	position: absolute;
	width: 0;
	top: -10px;
	left: var(--global--spacing-horizontal);
	border-style: solid;
	border-color: var(--primary-nav--border-color) transparent;
	border-width: 0 7px 10px 7px;
}

.wp-block-navigation > .wp-block-navigation__container > .has-child > .wp-block-navigation__container:after {
	top: -9px;
	border-color: var(--global--color-background) transparent;
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__container {
	background: var(--global--color-background);
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__container .wp-block-navigation__container {
	background: var(--global--color-background);
}

.wp-block-navigation:not(.has-text-color) .wp-block-navigation-link > a:hover,
.wp-block-navigation:not(.has-text-color) .wp-block-navigation-link > a:focus {
	color: var(--primary-nav--color-link-hover);
}

.wp-block-navigation:not(.has-text-color) .wp-block-navigation-link > a:hover {
	text-decoration: underline;
	text-decoration-style: dotted;
}

.wp-block-navigation:not(.has-text-color) .wp-block-navigation-link__content {
	color: currentColor;
}

p {
	line-height: var(--wp--typography--line-height, var(--global--line-height-body));
}

p.has-background {
	padding: var(--global--spacing-unit);
}

p.has-text-color a {
	color: var(--wp--style--color--link, var(--global--color-primary));
}

pre.wp-block-preformatted {
	overflow-x: auto;
	white-space: pre;
}

.wp-block-pullquote {
	padding: calc(2 * var(--global--spacing-unit)) 0;
	text-align: center;
	border-width: var(--pullquote--border-width);
	border-bottom-style: solid;
	border-top-style: solid;
	color: currentColor;
	border-color: currentColor;
	position: relative;

	/**
   * Block Options
   */
}

.wp-block-pullquote blockquote::before {
	color: currentColor;
	content: "“";
	display: block;
	position: relative;
	left: 0;
	font-size: 3rem;
	font-weight: 500;
	line-height: 1;
}

.wp-block-pullquote p {
	font-family: var(--pullquote--font-family);
	font-size: var(--pullquote--font-size);
	font-style: var(--pullquote--font-style);
	font-weight: 700;
	letter-spacing: var(--pullquote--letter-spacing);
	line-height: var(--pullquote--line-height);
	margin: 0;
}

.wp-block-pullquote a {
	color: currentColor;
}

.wp-block-pullquote .wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer {
	color: currentColor;
	display: block;
	font-size: var(--global--font-size-xs);
	font-style: var(--pullquote--font-style);
	text-transform: none;
}

.wp-block-pullquote:not(.is-style-solid-color) {
	background: none;
}

.wp-block-pullquote.alignleft:not(.is-style-solid-color) blockquote:before,
.wp-block-pullquote.alignleft:not(.is-style-solid-color) cite {
	text-align: center;
}

.wp-block-pullquote.alignwide > p,
.wp-block-pullquote.alignwide blockquote {
	max-width: var(--responsive--alignwide-width);
}

.wp-block-pullquote.alignfull:not(.is-style-solid-color) > p,
.wp-block-pullquote.alignfull:not(.is-style-solid-color) blockquote {
	padding: 0 calc(2 * var(--global--spacing-unit));
}

.wp-block-pullquote.is-style-solid-color {
	color: var(--pullquote--color-foreground);
	padding: calc(2.5 * var(--global--spacing-unit));
	border-width: var(--pullquote--border-width);
	border-style: solid;
	border-color: var(--pullquote--border-color);
}
@media (min-width: 600px) {

	.wp-block-pullquote.is-style-solid-color {
		padding: calc(5 * var(--global--spacing-unit));
	}
}

.wp-block-pullquote.is-style-solid-color blockquote::before {
	text-align: left;
}

.wp-block-pullquote.is-style-solid-color blockquote {
	margin: 0;
	max-width: inherit;
}

.wp-block-pullquote.is-style-solid-color blockquote p {
	font-size: var(--pullquote--font-size);
}

.wp-block-pullquote.is-style-solid-color .wp-block-pullquote__citation,
.wp-block-pullquote.is-style-solid-color cite,
.wp-block-pullquote.is-style-solid-color footer {
	color: currentColor;
}

.wp-block-pullquote.is-style-solid-color.alignleft,
.wp-block-pullquote.is-style-solid-color.alignright {
	padding: var(--global--spacing-unit);
}

.wp-block-pullquote.is-style-solid-color.alignleft blockquote,
.wp-block-pullquote.is-style-solid-color.alignright blockquote {
	max-width: initial;
}

.wp-block-query.has-background {
	padding: calc(0.666 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 482px) {

	.wp-block-query.has-background {
		padding: var(--global--spacing-vertical);
	}
}

.wp-block-quote {
	border-left: none;

	/**
   * Block Options
   */
}

.wp-block-quote:before {
	content: "“";
	font-size: var(--quote--font-size);
	line-height: var(--quote--line-height);
	left: 8px;
}

.has-background .wp-block-quote .wp-block-quote__citation,
[class*=background-color] .wp-block-quote .wp-block-quote__citation,
[style*=background-color] .wp-block-quote .wp-block-quote__citation,
.wp-block-cover[style*=background-image] .wp-block-quote .wp-block-quote__citation,
.has-background .wp-block-quote cite,
[class*=background-color] .wp-block-quote cite,
[style*=background-color] .wp-block-quote cite,
.wp-block-cover[style*=background-image] .wp-block-quote cite,
.has-background .wp-block-quote footer,
[class*=background-color] .wp-block-quote footer,
[style*=background-color] .wp-block-quote footer,
.wp-block-cover[style*=background-image] .wp-block-quote footer {
	color: currentColor;
}

.wp-block-quote.has-text-align-right {
	margin: var(--global--spacing-vertical) var(--global--spacing-horizontal) var(--global--spacing-vertical) auto;
	padding-right: 0;
	border-right: none;
}

.wp-block-quote.has-text-align-right:before {
	display: none;
}

.wp-block-quote.has-text-align-right p:before {
	content: "”";
	font-size: var(--quote--font-size);
	font-weight: normal;
	line-height: var(--quote--line-height);
	margin-right: 5px;
}

.wp-block-quote.has-text-align-center {
	margin: var(--global--spacing-vertical) auto;
}

.wp-block-quote.has-text-align-center:before {
	display: none;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
	padding-left: 0;
	padding-right: 0;

	/* Resetting margins to match _block-container.scss */
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
	font-size: var(--quote--font-size-large);
	font-style: var(--quote--font-style-large);
	line-height: var(--quote--line-height-large);
}

.wp-block-quote.is-large:before,
.wp-block-quote.is-style-large:before {
	font-size: var(--quote--font-size-large);
	line-height: var(--quote--line-height-large);
	left: calc(-1 * var(--global--spacing-horizontal));
}

.wp-block-quote.is-large.has-text-align-right:before,
.wp-block-quote.is-style-large.has-text-align-right:before {
	display: none;
}

.wp-block-quote.is-large.has-text-align-right p:before,
.wp-block-quote.is-style-large.has-text-align-right p:before {
	content: "”";
	font-size: var(--quote--font-size-large);
	font-weight: normal;
	line-height: var(--quote--line-height-large);
	margin-right: 10px;
}

.wp-block-quote.is-large .wp-block-quote__citation,
.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large .wp-block-quote__citation,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-sm);
}
@media only screen and (max-width: 481px) {

	.wp-block-quote.is-large,
	.wp-block-quote.is-style-large {
		padding-left: var(--global--spacing-horizontal);
	}

	.wp-block-quote.is-large:before,
	.wp-block-quote.is-style-large:before {
		left: 0;
	}

	.wp-block-quote.is-large.has-text-align-right,
	.wp-block-quote.is-style-large.has-text-align-right {
		padding-left: 0;
		padding-right: var(--global--spacing-horizontal);
	}

	.wp-block-quote.is-large.has-text-align-right:before,
	.wp-block-quote.is-style-large.has-text-align-right:before {
		right: 0;
	}

	.wp-block-quote.is-large.has-text-align-center,
	.wp-block-quote.is-style-large.has-text-align-center {
		padding-left: 0;
		padding-right: 0;
	}
}
@media only screen and (max-width: 481px) {

	.wp-block-quote.has-text-align-right {
		padding-left: 0;
		padding-right: calc(0.5 * var(--global--spacing-horizontal));
	}

	.wp-block-quote.has-text-align-right:before {
		right: 0;
	}

	.wp-block-quote.has-text-align-center {
		padding-left: 0;
		padding-right: 0;
	}
}

.wp-block-rss {
	padding-left: 0;
}

.wp-block-rss > li {
	list-style: none;
}

.wp-block-rss:not(.is-grid) > li {
	margin-top: calc(1.666 * var(--global--spacing-vertical));
	margin-bottom: calc(1.666 * var(--global--spacing-vertical));
}

.wp-block-rss:not(.is-grid) > li:first-child {
	margin-top: 0;
}

.wp-block-rss:not(.is-grid) > li:last-child {
	margin-bottom: 0;
}

.wp-block-rss.is-grid > li {
	margin-bottom: var(--global--spacing-vertical);
}

.wp-block-rss.is-grid > li:last-child {
	margin-bottom: 0;
}

.wp-block-rss.is-grid.columns-2 > li:nth-last-child(-n+2):nth-child(2n+1),
.wp-block-rss.is-grid.columns-2 > li:nth-last-child(-n+2):nth-child(2n+1) ~ li,
.wp-block-rss.is-grid.columns-3 > li:nth-last-child(-n+3):nth-child(3n+1),
.wp-block-rss.is-grid.columns-3 > li:nth-last-child(-n+3):nth-child(3n+1) ~ li,
.wp-block-rss.is-grid.columns-4 > li:nth-last-child(-n+4):nth-child(4n+1),
.wp-block-rss.is-grid.columns-4 > li:nth-last-child(-n+4):nth-child(4n+1) ~ li,
.wp-block-rss.is-grid.columns-5 > li:nth-last-child(-n+5):nth-child(5n+1),
.wp-block-rss.is-grid.columns-5 > li:nth-last-child(-n+5):nth-child(5n+1) ~ li,
.wp-block-rss.is-grid.columns-6 > li:nth-last-child(-n+6):nth-child(6n+1),
.wp-block-rss.is-grid.columns-6 > li:nth-last-child(-n+6):nth-child(6n+1) ~ li {
	margin-bottom: 0;
}

.wp-block-rss > li > * {
	margin-top: calc(0.333 * var(--global--spacing-vertical));
	margin-bottom: calc(0.333 * var(--global--spacing-vertical));
}

.wp-block-rss > li > *:first-child {
	margin-top: 0;
}

.wp-block-rss > li > *:last-child {
	margin-bottom: 0;
}

.wp-block-rss .wp-block-rss__item-title > a {
	display: inline-block;
	font-family: var(--latest-posts--title-font-family);
	font-size: var(--latest-posts--title-font-size);
	font-weight: var(--heading--font-weight);
	line-height: var(--global--line-height-heading);
	margin-bottom: calc(0.333 * var(--global--spacing-vertical));
}

.wp-block-rss .wp-block-rss__item-author {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-md);
	line-height: var(--global--line-height-body);
}

.wp-block-rss .wp-block-rss__item-publish-date {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
	line-height: var(--global--line-height-body);
}

[class*=inner-container] .wp-block-rss .wp-block-rss__item-publish-date,
.has-background .wp-block-rss .wp-block-rss__item-publish-date {
	color: currentColor;
}

.wp-block-rss .wp-block-rss__item-excerpt,
.wp-block-rss .wp-block-rss__item-full-content {
	font-family: var(--latest-posts--description-font-family);
	font-size: var(--latest-posts--description-font-size);
	line-height: var(--global--line-height-body);
	margin-top: calc(0.666 * var(--global--spacing-vertical));
}

.wp-block-rss.alignfull {
	padding-left: var(--global--spacing-unit);
	padding-right: var(--global--spacing-unit);
}

.entry-content [class*=inner-container] .wp-block-rss.alignfull,
.entry-content .has-background .wp-block-rss.alignfull {
	padding-left: 0;
	padding-right: 0;
}

.wp-block-search {
	max-width: var(--responsive--aligndefault-width);
}

.wp-block-search__button-only.aligncenter .wp-block-search__inside-wrapper {
	justify-content: center;
}

.wp-block-search .wp-block-search__label {
	font-size: var(--form--font-size);
	font-weight: var(--form--label-weight);
	margin-bottom: calc(var(--global--spacing-vertical) / 3);
}

.wp-block-search .wp-block-search__input {
	border: var(--form--border-width) solid var(--form--border-color);
	border-radius: var(--form--border-radius);
	color: var(--form--color-text);
	line-height: var(--form--line-height);
	max-width: inherit;
	margin-right: calc(-1 * var(--button--border-width));
	padding: var(--form--spacing-unit);
}

.wp-block-search .wp-block-search__input:focus {
	color: var(--form--color-text);
	border-color: var(--form--border-color);
}

.has-background .wp-block-search .wp-block-search__input {
	border-color: var(--local--color-primary, var(--global--color-primary)) !important;
}

.wp-block-search button.wp-block-search__button {
	margin-left: 0;
	line-height: 1;
}

.wp-block-search button.wp-block-search__button.has-icon {
	padding: 6px calc(0.5 * var(--button--padding-horizontal));
}

.wp-block-search button.wp-block-search__button.has-icon svg {
	width: 40px;
	height: 40px;
	fill: currentColor;
}

.has-background .wp-block-search button.wp-block-search__button:hover,
.has-background .wp-block-search button.wp-block-search__button:active {
	background-color: var(--local--color-background, var(--global--color-background)) !important;
	color: var(--local--color-primary, var(--global--color-primary)) !important;
}

.has-text-color .wp-block-search button.wp-block-search__button:hover,
.has-text-color .wp-block-search button.wp-block-search__button:active {
	color: var(--local--color-primary, var(--global--color-primary)) !important;
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
	background-color: var(--global--color-white);
	border: var(--form--border-width) solid var(--form--border-color);
	border-radius: var(--form--border-radius);
	padding: var(--form--border-width);
}

.has-background .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
	border-color: var(--local--color-primary, var(--global--color-primary)) !important;
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
	margin-left: 0;
	margin-right: 0;
	padding-left: var(--form--spacing-unit);
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input:focus {
	color: var(--form--color-text);
	outline-offset: -2px;
	outline: 2px dotted var(--form--border-color);
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper button.wp-block-search__button {
	padding: var(--button--padding-vertical) var(--button--padding-horizontal);
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper button.wp-block-search__button:hover {
	color: var(--global--color-dark-gray);
}

.is-dark-theme .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper button.wp-block-search__button {
	color: var(--global--color-dark-gray);
}

.is-dark-theme .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper button.wp-block-search__button:hover {
	background-color: var(--global--color-dark-gray);
	color: var(--global--color-white);
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper button.wp-block-search__button.has-icon {
	padding: 6px calc(0.5 * var(--button--padding-horizontal));
}

.wp-block-search__button {
	box-shadow: none;
}

hr {
	border-style: none;
	border-bottom: var(--separator--height) solid var(--separator--border-color);
	clear: both;
	margin-left: auto;
	margin-right: auto;
}

hr.wp-block-separator {
	border-bottom: var(--separator--height) solid var(--separator--border-color);
	opacity: 1;

	/**
   * Block Options
   */
}

hr.wp-block-separator:not(.is-style-dots):not(.alignwide) {
	max-width: var(--responsive--aligndefault-width);
}

hr.wp-block-separator:not(.is-style-dots).alignwide {
	max-width: var(--responsive--alignwide-width);
}

hr.wp-block-separator:not(.is-style-dots).alignfull {
	max-width: var(--responsive--alignfull-width);
}

hr.wp-block-separator.is-style-twentytwentyone-separator-thick {
	border-bottom-width: calc(3 * var(--separator--height));
}

hr.wp-block-separator.is-style-dots.has-background,
hr.wp-block-separator.is-style-dots.has-text-color {
	background-color: transparent !important;
}

hr.wp-block-separator.is-style-dots.has-background:before,
hr.wp-block-separator.is-style-dots.has-text-color:before {
	color: currentColor !important;
}

hr.wp-block-separator.is-style-dots:before {
	color: var(--separator--border-color);
	font-size: var(--global--font-size-xl);
	letter-spacing: var(--global--font-size-sm);
	padding-left: var(--global--font-size-sm);
}

.has-background hr.wp-block-separator,
[class*=background-color] hr.wp-block-separator,
[style*=background-color] hr.wp-block-separator,
.wp-block-cover[style*=background-image] hr.wp-block-separator {
	border-color: currentColor;
}

.wp-block-social-links a:focus {
	color: var(--global--color-primary);
}

.wp-block-social-links.is-style-twentytwentyone-social-icons-color a {
	color: var(--global--color-primary);
}

.wp-block-social-links.is-style-twentytwentyone-social-icons-color .wp-social-link,
.wp-block-social-links.is-style-twentytwentyone-social-icons-color.has-icon-background-color.has-icon-background-color .wp-social-link {
	background: none;
}

table,
.wp-block-table {
	width: 100%;
	min-width: 240px;
	border-collapse: collapse;
}

table thead,
table tfoot,
.wp-block-table thead,
.wp-block-table tfoot {
	text-align: center;
}

table th,
.wp-block-table th {
	font-family: var(--heading--font-family);
}

table td,
table th,
.wp-block-table td,
.wp-block-table th {
	padding: calc(0.5 * var(--global--spacing-unit));
	border: 1px solid;
}

table figcaption,
.wp-block-table figcaption {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
}

table.is-style-regular .has-background,
table.is-style-stripes .has-background,
table.is-style-stripes .has-background thead tr,
table.is-style-stripes .has-background tfoot tr,
table.is-style-stripes .has-background tbody tr,
.wp-block-table.is-style-regular .has-background,
.wp-block-table.is-style-stripes .has-background,
.wp-block-table.is-style-stripes .has-background thead tr,
.wp-block-table.is-style-stripes .has-background tfoot tr,
.wp-block-table.is-style-stripes .has-background tbody tr {
	color: var(--table--has-background-text-color);
}

table.is-style-stripes,
.wp-block-table.is-style-stripes {
	border-color: var(--table--stripes-border-color);
}

table.is-style-stripes th,
table.is-style-stripes td,
.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
	border-width: 0;
}

table.is-style-stripes tbody tr:nth-child(odd),
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
	background-color: var(--table--stripes-background-color);
}

table.is-style-stripes .has-background tbody tr:nth-child(odd),
.wp-block-table.is-style-stripes .has-background tbody tr:nth-child(odd) {
	background-color: var(--global--color-white-90);
}

table.wp-calendar-table td,
table.wp-calendar-table th {
	background: transparent;
	border: 0;
	text-align: center;
	line-height: 2;
	vertical-align: middle;
	word-break: normal;
}

table.wp-calendar-table th {
	font-weight: bold;
}

table.wp-calendar-table thead,
table.wp-calendar-table tbody {
	color: currentColor;
	border: 1px solid;
}

table.wp-calendar-table caption {
	font-weight: bold;
	text-align: left;
	margin-bottom: var(--global--spacing-unit);
	color: currentColor;
}

.wp-calendar-nav {
	text-align: left;
	margin-top: calc(var(--global--spacing-unit) / 2);
}

.wp-calendar-nav svg {
	height: 1em;
	vertical-align: middle;
}

.wp-calendar-nav svg path {
	fill: currentColor;
}

.wp-calendar-nav .wp-calendar-nav-next {
	float: right;
}

.wp-block-tag-cloud.alignfull {
	padding-left: var(--global--spacing-unit);
	padding-right: var(--global--spacing-unit);
}

.wp-block-verse {
	font-family: var(--entry-content--font-family);
}

.wp-block-video figcaption {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
	margin-top: calc(0.5 * var(--global--spacing-unit));
	margin-bottom: var(--global--spacing-unit);
	text-align: center;
}

* > figure > video {
	max-width: unset;
	width: 100%;
	vertical-align: middle;
}

:root .is-extra-small-text,
:root .has-extra-small-font-size {
	font-size: var(--global--font-size-xs);
}

:root .is-small-text,
:root .has-small-font-size {
	font-size: var(--global--font-size-sm);
}

:root .is-regular-text,
:root .has-regular-font-size,
:root .is-normal-font-size,
:root .has-normal-font-size,
:root .has-medium-font-size {
	font-size: var(--global--font-size-base);
}

:root .is-large-text,
:root .has-large-font-size {
	font-size: var(--global--font-size-lg);
	line-height: var(--global--line-height-heading);
}

:root .is-larger-text,
:root .has-larger-font-size,
:root .is-extra-large-text,
:root .has-extra-large-font-size {
	font-size: var(--global--font-size-xl);
	line-height: var(--global--line-height-heading);
}

:root .is-huge-text,
:root .has-huge-font-size {
	font-size: var(--global--font-size-xxl);
	line-height: var(--global--line-height-heading);
	font-weight: var(--heading--font-weight-page-title);
}

:root .is-gigantic-text,
:root .has-gigantic-font-size {
	font-size: var(--global--font-size-xxxl);
	line-height: var(--global--line-height-heading);
	font-weight: var(--heading--font-weight-page-title);
}

/* Block Alignments */

/**
 * These selectors set the default max width for content appearing inside a post or page.
 */

/**
 * .alignleft
 */
.alignleft {

	/*rtl:ignore*/
	text-align: left;
	margin-top: 0;
}

.entry-content > .alignleft {
	max-width: var(--responsive--aligndefault-width);
}

@media only screen and (min-width: 482px) {

	.alignleft {

		/*rtl:ignore*/
		float: left;

		/*rtl:ignore*/
		margin-right: var(--global--spacing-horizontal);
		margin-bottom: var(--global--spacing-vertical);
	}

	.entry-content > .alignleft {
		max-width: calc(50% - var(--responsive--alignleft-margin));
	}
}

/**
 * .aligncenter
 */
.aligncenter {
	clear: both;
	display: block;
	float: none;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

/**
 * .alignright
 */
.alignright {
	margin-top: 0;
	margin-bottom: var(--global--spacing-vertical);
}

.entry-content > .alignright {
	max-width: var(--responsive--aligndefault-width);
}

@media only screen and (min-width: 482px) {

	.alignright {

		/*rtl:ignore*/
		float: right;

		/*rtl:ignore*/
		margin-left: var(--global--spacing-horizontal);
	}

	.entry-content > .alignright {
		max-width: calc(50% - var(--responsive--alignright-margin));
	}
}

[class*=inner-container] > .alignleft + *,
[class*=inner-container] > .alignright + * {
	margin-top: 0;
}

/**
 * .alignwide
 */
.alignwide {
	clear: both;
}

/**
 * .alignfull
 */
.alignfull {
	clear: both;
}

.has-left-content {
	justify-content: flex-start;
}

.has-right-content {
	justify-content: flex-end;
}

.has-parallax {
	background-attachment: fixed;
}

.has-drop-cap:not(:focus)::first-letter {
	font-family: var(--heading--font-family);
	font-weight: var(--heading--font-weight);
	line-height: 0.66;
	text-transform: uppercase;
	font-style: normal;
	float: left;
	margin: 0.1em 0.1em 0 0;
	font-size: calc(1.2 * var(--heading--font-size-h1));
}

.has-drop-cap:not(:focus)::after {
	content: "";
	display: table;
	clear: both;
	padding-top: 14px;
}

.desktop-only {
	display: none;
}
@media only screen and (min-width: 482px) {

	.desktop-only {
		display: block;
	}
}

/* Category 06 contains all "bigger" components which contain elements of the previous two categories like header, footer, page template, single template, comments section, archives, ... */
.site-header {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	row-gap: var(--global--spacing-vertical);
}

.wp-custom-logo .site-header {
	align-items: center;
}
@media only screen and (min-width: 482px) {

	.site-header {
		padding-top: calc(var(--global--spacing-vertical) / 0.75);
	}
}
@media only screen and (min-width: 822px) {

	.site-header {
		padding-top: calc(2.4 * var(--global--spacing-vertical));
	}
}

.site-branding {
	color: var(--branding--color-text);
	margin-right: 140px;
}

.site-branding:last-child {
	margin-right: 0;
	width: 100%;
	text-align: center;
}
@media only screen and (min-width: 482px) {

	.site-branding {
		margin-right: initial;
		margin-top: 4px;
	}
}

.site-title {
	color: var(--branding--color-link);
	font-family: var(--branding--title--font-family);
	font-size: var(--branding--title--font-size-mobile);
	letter-spacing: normal;
	text-transform: var(--branding--title--text-transform);
	line-height: var(--global--line-height-heading);
	margin-bottom: calc(var(--global--spacing-vertical) / 6);
}

.site-title a {
	color: currentColor;
	font-weight: var(--branding--title--font-weight);
}

.site-title a:link,
.site-title a:visited,
.site-title a:active {
	color: currentColor;
}

.site-title a:hover,
.site-title a:focus {
	color: var(--branding--color-link-hover);
}
@media only screen and (min-width: 482px) {

	.site-title {
		font-size: var(--branding--title--font-size);
	}
}

.site-description {
	color: currentColor;
	font-family: var(--branding--description--font-family);
	font-size: var(--branding--description--font-size);
	line-height: 1.4;
}

.site-title > a {
	text-decoration-color: var(--global--color-secondary);
}

.site-logo {
	margin: calc(var(--global--spacing-vertical) / 2) 0;
}

.site-header > .site-logo {
	width: 100%;
	padding-bottom: calc(var(--global--spacing-vertical) * 1.5);
	border-bottom: 1px solid;
	text-align: center;
}

.site-logo .custom-logo {
	margin-left: auto;
	margin-right: auto;
	max-width: var(--branding--logo--max-width-mobile);
	max-height: var(--branding--logo--max-height-mobile);
	height: auto;
	display: inline-block;
	width: auto;
}
@media only screen and (min-width: 482px) {

	.site-logo .custom-logo {
		max-width: var(--branding--logo--max-width);
		max-height: var(--branding--logo--max-height);
		height: auto;
		width: auto;
	}
}

@media only screen and (max-width: 481px) {

	.site-header.has-logo:not(.has-title-and-tagline).has-menu .site-logo {
		position: absolute;
		padding-top: calc(0.5 * var(--global--spacing-vertical));
		margin-top: 0;
		top: var(--global--admin-bar--height);
	}

	.primary-navigation-open .site-header.has-logo:not(.has-title-and-tagline).has-menu .site-logo {
		display: none;
	}

	.site-header.has-logo:not(.has-title-and-tagline).has-menu .site-logo img {
		max-height: calc(var(--button--padding-vertical) - 0.25 * var(--global--spacing-unit) + 1.7em);
	}

	.site-header.has-logo.has-title-and-tagline {
		align-items: flex-start;
	}

	.site-header.has-logo.has-title-and-tagline.has-menu {
		justify-content: space-between;
	}

	.site-header.has-logo.has-title-and-tagline.has-menu .site-branding {
		max-width: calc(100% - 160px);
	}

	.site-header.has-logo.has-title-and-tagline .site-branding {
		margin-right: 0;
	}

	body:not(.primary-navigation-open) .site-header.has-logo.has-title-and-tagline:after {
		display: none;
	}

	body:not(.primary-navigation-open) .site-header.has-logo.has-title-and-tagline .primary-navigation {
		position: relative;
		top: 0;
	}

	body:not(.primary-navigation-open) .site-header.has-logo.has-title-and-tagline .menu-button-container {
		position: relative;
		padding-top: 0;
		margin-top: calc(0px - var(--button--padding-vertical) + 0.25 * var(--global--spacing-unit));
	}

	body:not(.primary-navigation-open) .site-header.has-logo.has-title-and-tagline .menu-button-container #primary-mobile-menu {
		padding-left: calc(var(--global--spacing-horizontal) * 0.6 - 4.5px);
		padding-right: calc(var(--global--spacing-horizontal) * 0.6 - 4.5px);
		margin-right: calc(0px - var(--global--spacing-horizontal) * 0.6);
	}

	.site-header:not(.has-logo).has-title-and-tagline .site-branding {
		margin-right: 0;
		max-width: calc(100% - 160px);
	}

	.site-header:not(.has-menu) {
		justify-content: center;
	}
}

.site-footer {
	padding-top: 0;
	padding-bottom: calc(1.7 * var(--global--spacing-vertical));
}

.no-widgets .site-footer {
	margin-top: calc(6 * var(--global--spacing-vertical));
}
@media only screen and (max-width: 481px) {

	.no-widgets .site-footer {
		margin-top: calc(3 * var(--global--spacing-vertical));
	}
}

.site-footer > .site-info {
	padding-top: var(--global--spacing-vertical);
	color: var(--footer--color-text);
	font-family: var(--footer--font-family);
	font-size: var(--footer--font-size);
	line-height: var(--global--line-height-body);
	border-top: 3px solid var(--global--color-border);
}

.site-footer > .site-info .site-name {
	text-transform: var(--branding--title--text-transform);
	font-size: var(--branding--title--font-size);
}

.site-footer > .site-info .privacy-policy,
.site-footer > .site-info .powered-by {
	margin-top: calc(0.5 * var(--global--spacing-vertical));
}
@media only screen and (min-width: 822px) {

	.site-footer > .site-info {
		display: flex;
		align-items: center;
	}

	.site-footer > .site-info .site-name {
		margin-right: calc(0.5 * var(--global--spacing-vertical));
	}

	.site-footer > .site-info .privacy-policy,
	.site-footer > .site-info .powered-by {
		margin-top: initial;
		margin-left: auto;
	}

	.site-footer > .site-info .privacy-policy + .powered-by {
		margin-left: calc(0.5 * var(--global--spacing-vertical));
	}
}

.site-footer > .site-info a {
	color: var(--footer--color-link);
}

.site-footer > .site-info a:link,
.site-footer > .site-info a:visited,
.site-footer > .site-info a:active {
	color: var(--footer--color-link);
}

.site-footer > .site-info a:hover {
	color: var(--footer--color-link-hover);
}

.site-footer > .site-info a:focus {
	color: var(--footer--color-link-hover);
}

.is-dark-theme .site-footer > .site-info a:focus {
	color: var(--wp--style--color--link, var(--global--color-background));
}

.has-background-white .site-footer > .site-info a:focus {
	color: var(--wp--style--color--link, var(--global--color-white));
}

.singular .entry-header {
	border-bottom: 3px solid var(--global--color-border);
	padding-bottom: calc(2 * var(--global--spacing-vertical));
	margin-bottom: calc(3 * var(--global--spacing-vertical));
}

.home .entry-header {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.singular .has-post-thumbnail .entry-header {
	border-bottom: none;
	padding-bottom: calc(1.3 * var(--global--spacing-vertical));
	margin-bottom: 0;
}

.no-results.not-found > *:first-child {
	margin-bottom: calc(3 * var(--global--spacing-vertical));
}

.page-links {
	clear: both;
}

.page-links .post-page-numbers {
	display: inline-block;
	margin-left: calc(0.66 * var(--global--spacing-unit));
	margin-right: calc(0.66 * var(--global--spacing-unit));
	min-width: 44px;
	min-height: 44px;
}

.page-links .post-page-numbers:first-child {
	margin-left: 0;
}

.entry-title {
	color: var(--entry-header--color);
	font-size: var(--entry-header--font-size);
	letter-spacing: var(--heading--letter-spacing-h2);
	line-height: var(--heading--line-height-h2);
	overflow-wrap: break-word;
}

.entry-title a {
	color: var(--entry-header--color-link);
	text-underline-offset: 0.15em;
}

.entry-title a:hover {
	color: var(--entry-header--color-hover);
}

.entry-title a:focus {
	color: var(--entry-header--color-focus);
}

.entry-title a:active {
	color: var(--entry-header--color-link);
}

.singular .entry-title {
	font-size: var(--global--font-size-page-title);
}

h1.entry-title {
	line-height: var(--heading--line-height-h1);
	font-weight: var(--heading--font-weight-page-title);
}

/**
 * Entry Content
 */
.entry-content,
.entry-summary {
	font-family: var(--entry-content--font-family);
}

.entry-content p {
	word-wrap: break-word;
}

.entry-content > iframe[style] {
	margin: var(--global--spacing-vertical) 0 !important;
	max-width: 100% !important;
}

.entry-footer {
	color: var(--global--color-primary);
	clear: both;
	float: none;
	font-size: var(--global--font-size-xs);
	display: block;
}

.entry-footer > span {
	display: inline-block;
}

.entry-footer a {
	color: currentColor;
}

.entry-footer a:hover,
.entry-footer a:focus {
	color: var(--global--color-primary-hover);
}

.entry-footer a:active {
	color: currentColor;
}

.site-main > article > .entry-footer {
	margin-top: var(--global--spacing-vertical);
	padding-top: var(--global--spacing-unit);
	padding-bottom: calc(3 * var(--global--spacing-vertical));
	border-bottom: var(--separator--height) solid var(--separator--border-color);
}

body:not(.single) .site-main > article:last-of-type .entry-footer {
	border-bottom: var(--separator--height) solid transparent;
}

.single .site-main > article > .entry-footer {
	margin-top: calc(3.4 * var(--global--spacing-vertical));
	margin-bottom: calc(3.4 * var(--global--spacing-vertical));
	padding-bottom: 0;
	padding-top: calc(0.8 * var(--global--spacing-vertical));
	border-top: 3px solid var(--separator--border-color);
	border-bottom: var(--separator--height) solid transparent;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: calc(2 * var(--global--spacing-horizontal));
}

.single .site-main > article > .entry-footer .post-taxonomies,
.single .site-main > article > .entry-footer .full-size-link {
	justify-content: flex-end;
	text-align: right;
}

.single .site-main > article > .entry-footer .full-size-link:first-child:last-child {
	grid-column: span 2;
}

.single .site-main > article > .entry-footer .posted-on,
.single .site-main > article > .entry-footer .byline,
.single .site-main > article > .entry-footer .cat-links,
.single .site-main > article > .entry-footer .tags-links {
	display: block;
}
@media only screen and (max-width: 481px) {

	.single .site-main > article > .entry-footer {
		display: block;
	}

	.single .site-main > article > .entry-footer .full-size-link {
		display: block;
	}

	.single .site-main > article > .entry-footer .post-taxonomies,
	.single .site-main > article > .entry-footer .full-size-link {
		text-align: left;
	}
}

/**
 * Post Thumbnails
 */
.post-thumbnail {
	text-align: center;
}

.post-thumbnail .wp-post-image {
	display: block;
	width: auto;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: calc(2 * var(--global--spacing-vertical));
}

/**
 * Author
 */
.author-bio {
	position: relative;
	font-size: var(--global--font-size-xs);
	max-width: var(--responsive--aligndefault-width);
}

.site-main > article > .author-bio {
	margin-top: calc(2 * var(--global--spacing-vertical));
}

.author-bio.show-avatars .avatar {
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}

.author-bio.show-avatars .author-bio-content {
	display: inline-block;
	padding-left: var(--global--spacing-horizontal);
	max-width: calc(var(--responsive--aligndefault-width) - 90px);
}

.author-bio .author-bio-content .author-title {
	font-family: var(--entry-author-bio--font-family);
	font-size: var(--entry-author-bio--font-size);
	display: inline;
}

.author-bio .author-bio-content .author-description {
	font-size: var(--global--font-size-xs);
	margin-top: calc(0.5 * var(--global--spacing-vertical));
	margin-bottom: calc(0.5 * var(--global--spacing-vertical));
}

.page-title {
	font-size: var(--global--font-size-page-title);
}

h1.page-title,
h2.page-title {
	font-weight: var(--heading--font-weight-page-title);
}

h1.page-title {
	line-height: var(--heading--line-height-h1);
}

.page-header {
	border-bottom: 3px solid var(--global--color-border);
	padding-bottom: calc(2 * var(--global--spacing-vertical));
}

.archive .content-area .format-aside .entry-content,
.archive .content-area .format-status .entry-content,
.archive .content-area .format-link .entry-content,
.search .content-area .format-aside .entry-content,
.search .content-area .format-status .entry-content,
.search .content-area .format-link .entry-content,
.blog .content-area .format-aside .entry-content,
.blog .content-area .format-status .entry-content,
.blog .content-area .format-link .entry-content {
	font-size: var(--global--font-size-lg);
}

.archive .format-image .entry-content,
.archive .format-gallery .entry-content,
.archive .format-video .entry-content,
.search .format-image .entry-content,
.search .format-gallery .entry-content,
.search .format-video .entry-content,
.blog .format-image .entry-content,
.blog .format-gallery .entry-content,
.blog .format-video .entry-content {
	margin-top: calc(2 * var(--global--spacing-vertical));
}

.archive .entry-footer .cat-links,
.archive .entry-footer .tags-links,
.search .entry-footer .cat-links,
.search .entry-footer .tags-links,
.blog .entry-footer .cat-links,
.blog .entry-footer .tags-links {
	display: block;
}

.archive.logged-in .entry-footer .posted-on,
.search.logged-in .entry-footer .posted-on,
.blog.logged-in .entry-footer .posted-on {
	margin-right: calc(0.5 * var(--global--spacing-unit));
}

.archive-description {
	margin-top: var(--global--spacing-vertical);
	font-size: var(--global--font-size-xl);
	line-height: var(--global--line-height-heading);
}

.error404 main p {
	font-size: var(--global--font-size-lg);
	margin-bottom: calc(var(--global--spacing-vertical) * 1.6666666667);
}

.search-no-results .page-content {
	margin-top: calc(3 * var(--global--spacing-vertical));
}

/**
 * Comments Wrapper
 */
.comments-area > * {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.comments-area > *:first-child {
	margin-top: 0;
}

.comments-area > *:last-child {
	margin-bottom: 0;
}

.comments-area.show-avatars .avatar {
	border-radius: 50%;
	position: absolute;
	top: 10px;
}

.comments-area.show-avatars .fn {
	display: inline-block;
	padding-left: 85px;
}

.comments-area.show-avatars .comment-metadata {
	padding: 8px 0 9px 85px;
}

/**
 * Comment Title
 */
.comments-title,
.comment-reply-title {
	font-size: var(--heading--font-size-h2);
	letter-spacing: var(--heading--letter-spacing-h2);
}

.comment-reply-title {
	display: flex;
	justify-content: space-between;
}

.comment-reply-title small a {
	font-family: var(--global--font-secondary);
	font-size: var(--global--font-size-xs);
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
}

/* Nested comment reply title*/
.comment .comment-respond .comment-reply-title {
	font-size: var(--global--font-size-lg);
}

/**
 * Comment Lists
 */
.comment-list {
	padding-left: 0;
	list-style: none;
}

.comment-list > li {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.comment-list .children {
	list-style: none;
	padding-left: 0;
}

.comment-list .children > li {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

@media only screen and (min-width: 482px) {

	.comment-list .depth-2,
	.comment-list .depth-3 {
		padding-left: calc(4 * var(--global--spacing-horizontal));
	}
}

/**
 * Comment Meta
 */
.comment-meta .comment-author {
	line-height: var(--global--line-height-heading);
	margin-bottom: calc(0.25 * var(--global--spacing-unit));
}
@media only screen and (min-width: 482px) {

	.comment-meta .comment-author {
		margin-bottom: 0;
		padding-right: 0;
	}
}

.comment-meta .comment-author .fn {
	font-family: var(--global--font-secondary);
	font-weight: normal;
	font-size: var(--global--font-size-lg);
	hyphens: auto;
	word-wrap: break-word;
	word-break: break-word;
}

.comment-meta .comment-metadata {
	color: var(--global--color-primary);
	font-size: var(--global--font-size-xs);
	padding: 8px 0 9px 0;
}

.comment-meta .comment-metadata .edit-link {
	margin-left: var(--global--spacing-horizontal);
}
@media only screen and (min-width: 482px) {

	.comment-meta {
		margin-right: inherit;
	}

	.comment-meta .comment-author {
		max-width: inherit;
	}
}

.reply {
	font-size: var(--global--font-size-sm);
	line-height: var(--global--line-height-heading);
}

.bypostauthor {
	display: block;
}

.says {
	display: none;
}

.pingback .url,
.trackback .url {
	font-family: var(--global--font-primary);
}

.comment-body {
	position: relative;
	margin-bottom: calc(1.7 * var(--global--spacing-vertical));
}

.comment-body > * {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.comment-body .reply {
	margin: 0;
}

.comment-content {
	word-wrap: break-word;
}

.pingback .comment-body,
.trackback .comment-body {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.comment-respond {
	margin-top: var(--global--spacing-vertical);
}

.comment-respond > * {
	margin-top: var(--global--spacing-unit);
	margin-bottom: var(--global--spacing-unit);
}

.comment-respond > *:first-child {
	margin-top: 0;
}

.comment-respond > *:last-child {
	margin-bottom: 0;
}

.comment-respond > *:last-child.comment-form {
	margin-bottom: var(--global--spacing-vertical);
}

.comment-author {
	padding-top: 3px;
}

.comment-author .url {
	color: currentColor;
}

.comment-form {
	display: flex;
	flex-wrap: wrap;
}

.comment-form > * {
	flex-basis: 100%;
}

.comment-form .comment-notes {
	font-size: var(--global--font-size-sm);
}

.comment-form .comment-form-url,
.comment-form .comment-form-comment {
	width: 100%;
}

.comment-form .comment-form-author,
.comment-form .comment-form-email {
	flex-basis: 0;
	flex-grow: 1;
}
@media only screen and (max-width: 481px) {

	.comment-form .comment-form-author,
	.comment-form .comment-form-email {
		flex-basis: 100%;
	}
}

.comment-form .comment-form-cookies-consent > label,
.comment-form .comment-notes {
	font-size: var(--global--font-size-xs);
	font-weight: normal;
}

.comment-form > p {
	margin-bottom: var(--global--spacing-unit);
}

.comment-form > p:first-of-type {
	margin-top: 0;
}

.comment-form > p:last-of-type {
	margin-bottom: 0;
}

.comment-form > p label,
.comment-form > p input[type=email],
.comment-form > p input[type=text],
.comment-form > p input[type=url],
.comment-form > p textarea {
	display: block;
	font-size: var(--global--font-size-sm);
	margin-bottom: calc(0.5 * var(--global--spacing-unit));
	width: 100%;
	font-weight: var(--form--label-weight);
}

.comment-form > p.comment-form-cookies-consent {
	display: flex;
}
@media only screen and (min-width: 482px) {

	.comment-form > p.comment-form-author {
		margin-right: calc(1.5 * var(--global--spacing-horizontal));
	}

	.comment-form > p.comment-notes,
	.comment-form > p.logged-in-as {
		display: block;
	}
}

.menu-button-container {
	display: none;
	justify-content: space-between;
	position: absolute;
	right: 0;
	padding-top: calc(0.5 * var(--global--spacing-vertical));
	padding-bottom: calc(0.25 * var(--global--spacing-vertical));
}
@media only screen and (max-width: 481px) {

	.menu-button-container {
		display: flex;
	}
}

.menu-button-container #primary-mobile-menu {
	display: flex;
	margin-left: auto;
	padding: calc(var(--button--padding-vertical) - 0.25 * var(--global--spacing-unit)) calc(0.5 * var(--button--padding-horizontal));
	font-size: var(--primary-nav--font-size-button);
	font-weight: var(--primary-nav--font-weight-button);
	background-color: transparent;
	border: none;
	color: var(--primary-nav--color-link);
}

.menu-button-container #primary-mobile-menu .dropdown-icon {
	display: flex;
	align-items: center;
}

.menu-button-container #primary-mobile-menu .dropdown-icon .svg-icon {
	margin-left: calc(0.25 * var(--global--spacing-unit));
}

.menu-button-container #primary-mobile-menu .dropdown-icon.open .svg-icon {
	position: relative;
	top: -1px;
}

.menu-button-container #primary-mobile-menu .dropdown-icon.close {
	display: none;
}

.menu-button-container #primary-mobile-menu[aria-expanded*=true] .dropdown-icon.open {
	display: none;
}

.menu-button-container #primary-mobile-menu[aria-expanded*=true] .dropdown-icon.close {
	display: flex;
}

.has-logo.has-title-and-tagline .menu-button-container #primary-mobile-menu[aria-expanded*=true] .dropdown-icon.close {
	animation-name: twentytwentyone-close-button-transition;
	animation-duration: 0.3s;
}

.primary-navigation-open .menu-button-container {
	width: 100%;
	z-index: 500;
	background-color: var(--global--color-background);
}

.primary-navigation-open .menu-button-container #primary-mobile-menu {
	position: static;
}

.primary-navigation {
	position: absolute;
	top: var(--global--admin-bar--height);
	right: 0;
	color: var(--primary-nav--color-text);
	font-size: var(--primary-nav--font-size);
	line-height: 1.15;
	margin-top: 0;
	margin-bottom: 0;
}

.primary-navigation > .primary-menu-container {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-top: calc(var(--button--line-height) * var(--primary-nav--font-size-button) + 42px + 5px);
	padding-left: var(--global--spacing-unit);
	padding-right: var(--global--spacing-unit);
	padding-bottom: var(--global--spacing-horizontal);
	background-color: var(--global--color-background);
	transform: translateY(var(--global--spacing-vertical));
}
@media (prefers-reduced-motion: no-preference) {

	.primary-navigation > .primary-menu-container {
		transition: all 0.15s ease-in-out;
	}
}
@media only screen and (max-width: 481px) {

	.primary-navigation > .primary-menu-container {
		height: 100vh;
		z-index: 499;
		overflow-x: hidden;
		overflow-y: auto;
		border: 2px solid transparent;
	}

	.has-logo.has-title-and-tagline .primary-navigation > .primary-menu-container {
		position: fixed;
		transform: translateY(0) translateX(100%);
	}

	.admin-bar .has-logo.has-title-and-tagline .primary-navigation > .primary-menu-container {
		top: var(--global--admin-bar--height);
	}

	.admin-bar .primary-navigation > .primary-menu-container {
		height: calc(100vh - var(--global--admin-bar--height));
	}

	.primary-navigation > .primary-menu-container:focus {
		border: 2px solid var(--global--color-primary);
	}
}
@media only screen and (max-width: 481px) {

	.primary-navigation-open .primary-navigation {
		width: 100%;
		position: fixed;
		z-index: 2;
	}
}

.primary-navigation-open .primary-navigation > .primary-menu-container {
	position: absolute;
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}
@media only screen and (max-width: 481px) {

	.primary-navigation-open .has-logo.has-title-and-tagline .primary-navigation > .primary-menu-container {
		transform: translateX(0) translateY(0);
	}
}
@media only screen and (min-width: 482px) {

	.primary-navigation {
		position: relative;
		margin-left: auto;
	}

	.primary-navigation > .primary-menu-container {
		visibility: visible;
		opacity: 1;
		position: relative;
		padding: 0;
		background-color: transparent;
		overflow: initial;
		transform: none;
	}

	.primary-navigation #toggle-menu {
		display: none;
	}

	.primary-navigation > .primary-menu-container ul > li .sub-menu-toggle[aria-expanded=false] ~ ul {
		display: none;
	}

	.admin-bar .primary-navigation {
		top: initial;
	}

	.admin-bar .primary-navigation > .primary-menu-container {
		top: initial;
	}
}

.primary-navigation > div > .menu-wrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	max-width: none;
	padding-left: 0;
	position: relative;
}
@media only screen and (max-width: 481px) {

	.primary-navigation > div > .menu-wrapper {
		padding-bottom: 100px;
	}

	.primary-navigation > div > .menu-wrapper ul {
		padding-left: 0;
	}
}

.primary-navigation > div > .menu-wrapper li {
	display: block;
	position: relative;
	width: 100%;
}
@media only screen and (min-width: 482px) {

	.primary-navigation > div > .menu-wrapper li {
		margin: 0;
		width: inherit;
	}

	.primary-navigation > div > .menu-wrapper li:last-child {
		margin-right: 0;
	}
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle {
	display: flex;
	height: calc(2 * var(--primary-nav--padding) + 1.15em + 1px);
	width: 44px;
	padding: 0;
	justify-content: center;
	align-items: center;
	background: transparent;
	color: currentColor;
	border: none;
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle:focus {
	outline: 2px solid var(--wp--style--color--link, var(--global--color-primary));
}
@media only screen and (max-width: 481px) {

	.primary-navigation > div > .menu-wrapper .sub-menu-toggle {
		display: none;
	}
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle .icon-plus,
.primary-navigation > div > .menu-wrapper .sub-menu-toggle .icon-minus {
	height: 100%;
	display: flex;
	align-items: center;
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle .icon-plus svg,
.primary-navigation > div > .menu-wrapper .sub-menu-toggle .icon-minus svg {
	margin-top: -1px;
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle .icon-minus {
	display: none;
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle[aria-expanded=true] .icon-minus {
	display: flex;
}

.primary-navigation > div > .menu-wrapper .sub-menu-toggle[aria-expanded=true] .icon-plus {
	display: none;
}

.primary-navigation > div > .menu-wrapper > li > .sub-menu {
	position: relative;
}
@media only screen and (min-width: 482px) and (prefers-reduced-motion: no-preference) {

	.primary-navigation > div > .menu-wrapper > li > .sub-menu {
		transition: all 0.5s ease;
	}
}
@media only screen and (min-width: 482px) {

	.primary-navigation > div > .menu-wrapper > li > .sub-menu {
		left: 0;
		margin: 0;
		min-width: max-content;
		position: absolute;
		top: 100%;
		padding-top: 3px;
		z-index: 88888;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu:before,
	.primary-navigation > div > .menu-wrapper > li > .sub-menu:after {
		content: "";
		display: block;
		position: absolute;
		width: 0;
		top: -10px;
		left: var(--global--spacing-horizontal);
		border-style: solid;
		border-color: var(--primary-nav--border-color) transparent;
		border-width: 0 7px 10px 7px;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu:after {
		top: -9px;
		border-color: var(--global--color-background) transparent;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu li {
		background: var(--global--color-background);
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-left {

		/* rtl:ignore */
		left: 0;

		/* rtl:ignore */
		right: auto;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-left:before,
	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-left:after {

		/* rtl:ignore */
		left: var(--global--spacing-horizontal);

		/* rtl:ignore */
		right: auto;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-right {

		/* rtl:ignore */
		right: 0;

		/* rtl:ignore */
		left: auto;
	}

	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-right:before,
	.primary-navigation > div > .menu-wrapper > li > .sub-menu.submenu-reposition-right:after {

		/* rtl:ignore */
		left: auto;

		/* rtl:ignore */
		right: var(--global--spacing-horizontal);
	}
}

.primary-navigation .primary-menu > .menu-item:hover > a {
	color: var(--primary-nav--color-link-hover);
}
@media only screen and (min-width: 482px) {

	.primary-navigation .primary-menu-container {
		margin-right: calc(0px - var(--primary-nav--padding));
		margin-left: calc(0px - var(--primary-nav--padding));
	}

	.primary-navigation .primary-menu-container > ul > .menu-item {
		display: flex;
	}

	.primary-navigation .primary-menu-container > ul > .menu-item > a {
		padding-left: var(--primary-nav--padding);
		padding-right: var(--primary-nav--padding);
	}

	.primary-navigation .primary-menu-container > ul > .menu-item > a + .sub-menu-toggle {
		margin-left: calc(5px - var(--primary-nav--padding));
	}
}

.primary-navigation a {
	display: block;
	font-family: var(--primary-nav--font-family-mobile);
	font-size: var(--primary-nav--font-size-mobile);
	font-weight: var(--primary-nav--font-weight);
	padding: var(--primary-nav--padding) 0;
	text-decoration: none;
}
@media only screen and (min-width: 482px) {

	.primary-navigation a {
		display: block;
		font-family: var(--primary-nav--font-family);
		font-size: var(--primary-nav--font-size);
		font-weight: var(--primary-nav--font-weight);
	}
}

.primary-navigation a + svg {
	fill: var(--primary-nav--color-text);
}

.primary-navigation a:hover,
.primary-navigation a:link,
.primary-navigation a:visited {
	color: var(--primary-nav--color-link-hover);
}

.primary-navigation a:hover {
	text-decoration: underline;
	text-decoration-style: dotted;
}

.primary-navigation a:focus {
	position: relative;
	z-index: 99999;
	outline-offset: 0;
	text-decoration-thickness: 2px;
}

.primary-navigation .current-menu-item > a:first-child,
.primary-navigation .current_page_item > a:first-child {
	text-decoration: underline;
	text-decoration-style: solid;
}

.primary-navigation .current-menu-item > a:first-child:hover,
.primary-navigation .current_page_item > a:first-child:hover {
	text-decoration: underline;
	text-decoration-style: dotted;
}

.primary-navigation .sub-menu {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: var(--primary-nav--padding);
	border: 1px solid var(--primary-nav--border-color);
}

.primary-navigation .sub-menu .sub-menu {
	border: none;
}
@media only screen and (min-width: 482px) {

	.primary-navigation .sub-menu > .menu-item > .sub-menu {
		padding: 0;
	}
}
@media only screen and (max-width: 481px) {

	.primary-navigation .sub-menu .menu-item:last-child {
		margin-bottom: 0;
	}
}

.primary-navigation .sub-menu .menu-item > a {
	padding: calc(1.25 * var(--primary-nav--padding)) var(--primary-nav--padding);
	display: block;
	font-size: var(--primary-nav--font-size-sub-menu-mobile);
	font-style: var(--primary-nav--font-style-sub-menu-mobile);
}
@media only screen and (min-width: 482px) {

	.primary-navigation .sub-menu .menu-item > a {
		font-size: var(--primary-nav--font-size-sub-menu);
		font-style: var(--primary-nav--font-style);
	}
}

.primary-navigation .menu-item-has-children > .svg-icon {
	display: none;
}
@media only screen and (min-width: 482px) {

	.primary-navigation .menu-item-has-children > .svg-icon {
		display: inline-block;
		height: 100%;
	}

	.primary-navigation .menu-item-has-children .sub-menu .svg-icon {
		display: none;
	}
}

.primary-navigation .menu-item-description {
	display: block;
	clear: both;
	font-size: var(--global--font-size-xs);
	text-transform: none;
	line-height: 1.7;
}

.primary-navigation .menu-item-description > span {
	display: inline-block;
}

@media only screen and (max-width: 481px) {

	.lock-scrolling .site {
		position: fixed;
		max-width: 100%;
		width: 100%;
	}
}
@keyframes twentytwentyone-close-button-transition {

	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.footer-navigation {
	margin-top: calc(2 * var(--global--spacing-vertical));
	margin-bottom: var(--global--spacing-vertical);
	color: var(--footer--color-text);
	font-size: var(--global--font-size-xs);
	font-family: var(--footer--font-family);
}

.footer-navigation-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
}

.footer-navigation-wrapper li {
	display: inline;
	line-height: 3;
}

.footer-navigation-wrapper li a {
	padding: calc(1.25 * var(--primary-nav--padding)) var(--primary-nav--padding);
	color: var(--footer--color-link);
}

.footer-navigation-wrapper li a:link,
.footer-navigation-wrapper li a:visited,
.footer-navigation-wrapper li a:active {
	color: var(--footer--color-link);
}

.footer-navigation-wrapper li a:hover {
	text-decoration: underline;
	text-decoration-style: dotted;
	text-decoration-skip-ink: none;
	color: var(--footer--color-link-hover);
}

.is-dark-theme .footer-navigation-wrapper li a:focus .svg-icon {
	fill: var(--wp--style--color--link, var(--global--color-background));
}

.has-background-white .footer-navigation-wrapper li a:focus .svg-icon {
	fill: var(--wp--style--color--link, var(--global--color-white));
}

.footer-navigation-wrapper li .svg-icon {
	vertical-align: middle;
	fill: var(--footer--color-link);
}

.footer-navigation-wrapper li .svg-icon:hover {
	transform: scale(1.1);
}
@media (prefers-reduced-motion: no-preference) {

	.footer-navigation-wrapper li .svg-icon {
		transition: transform 0.1s ease;
	}
}

.footer-navigation-wrapper .sub-menu-toggle,
.footer-navigation-wrapper .menu-item-description {
	display: none;
}

/* Next/Previous navigation */
.navigation {
	color: var(--global--color-primary);
}

.navigation a {
	color: var(--global--color-primary);
	text-decoration: none;
}

.navigation a:hover {
	color: var(--global--color-primary-hover);
	text-decoration: underline;
	text-decoration-style: dotted;
}

.navigation a:focus {
	color: var(--global--color-secondary);
}

.navigation a:active {
	color: var(--global--color-primary);
}

.navigation .nav-links > * {
	min-width: 44px;
	min-height: 44px;
}

.navigation .nav-links .nav-next a,
.navigation .nav-links .nav-previous a {
	display: flex;
	flex-direction: column;
}

.navigation .nav-links .dots {
	text-align: center;
}
@media only screen and (min-width: 592px) {

	.navigation .nav-links {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.navigation .nav-links .nav-next,
	.navigation .nav-links .nav-previous {
		flex: 0 1 auto;
		margin-bottom: inherit;
		margin-top: inherit;
		max-width: calc(50% - 0.5 * var(--global--spacing-unit));
	}

	.navigation .nav-links .nav-next {
		text-align: right;
	}
}

.navigation .svg-icon {
	display: inline-block;
	fill: currentColor;
	vertical-align: middle;
	position: relative;
}

.navigation .nav-previous .svg-icon,
.navigation .prev .svg-icon {
	top: -2px;
	margin-right: calc(0.25 * var(--global--spacing-unit));
}

.navigation .nav-next .svg-icon,
.navigation .next .svg-icon {
	top: -1px;
	margin-left: calc(0.25 * var(--global--spacing-unit));
}

.post-navigation {
	margin: var(--global--spacing-vertical) auto;
}
@media only screen and (min-width: 822px) {

	.post-navigation {
		margin: var(--global--spacing-vertical) auto;
	}
}

.post-navigation .meta-nav {
	line-height: var(--global--line-height-body);
	color: var(--global--color-primary);
}

.post-navigation .post-title {
	display: inline-block;
	font-family: var(--global--font-primary);
	font-size: var(--global--font-size-lg);
	font-weight: var(--pagination--font-weight-strong);
	line-height: var(--global--line-height-heading);
}
@media only screen and (min-width: 822px) {

	.post-navigation .post-title {
		margin: 5px calc(24px + 0.25 * var(--global--spacing-unit)) 0;
	}
}
@media only screen and (min-width: 482px) {

	.post-navigation .nav-links {
		justify-content: space-between;
	}
}

.post-navigation .nav-next,
.post-navigation .nav-previous {
	margin-top: var(--global--spacing-vertical);
	margin-bottom: var(--global--spacing-vertical);
}

.post-navigation .nav-next:first-child,
.post-navigation .nav-previous:first-child {
	margin-top: 0;
}

.post-navigation .nav-next:last-child,
.post-navigation .nav-previous:last-child {
	margin-bottom: 0;
}

.pagination,
.comments-pagination {
	border-top: 3px solid var(--global--color-border);
	padding-top: var(--global--spacing-vertical);
	margin: var(--global--spacing-vertical) auto;
}
@media only screen and (min-width: 822px) {

	.pagination,
	.comments-pagination {
		margin: var(--global--spacing-vertical) auto;
	}
}

.pagination .nav-links,
.comments-pagination .nav-links {
	margin-top: calc(-1 * var(--global--spacing-vertical));
}

.pagination .nav-links a:hover,
.comments-pagination .nav-links a:hover {
	color: var(--pagination--color-link-hover);
}

.is-dark-theme .pagination .nav-links a:active,
.is-dark-theme .pagination .nav-links a:hover:active,
.is-dark-theme .pagination .nav-links a:hover:focus,
.is-dark-theme .comments-pagination .nav-links a:active,
.is-dark-theme .comments-pagination .nav-links a:hover:active,
.is-dark-theme .comments-pagination .nav-links a:hover:focus {
	color: var(--global--color-background);
}

.has-background-white .pagination .nav-links a:active,
.has-background-white .pagination .nav-links a:hover:active,
.has-background-white .pagination .nav-links a:hover:focus,
.has-background-white .comments-pagination .nav-links a:active,
.has-background-white .comments-pagination .nav-links a:hover:active,
.has-background-white .comments-pagination .nav-links a:hover:focus {
	color: var(--global--color-white);
}

.pagination .nav-links > *,
.comments-pagination .nav-links > * {
	color: var(--pagination--color-text);
	font-family: var(--pagination--font-family);
	font-size: var(--pagination--font-size);
	font-weight: var(--pagination--font-weight);
	margin-top: var(--global--spacing-vertical);
	margin-left: calc(0.66 * var(--global--spacing-unit));
	margin-right: calc(0.66 * var(--global--spacing-unit));
}

.pagination .nav-links > *.current,
.comments-pagination .nav-links > *.current {
	text-decoration: underline;
}

.pagination .nav-links > *:not(.dots):not(.current):hover,
.comments-pagination .nav-links > *:not(.dots):not(.current):hover {
	text-decoration-style: dotted;
}

.pagination .nav-links > *:first-child,
.comments-pagination .nav-links > *:first-child {
	margin-left: 0;
}

.pagination .nav-links > *:last-child,
.comments-pagination .nav-links > *:last-child {
	margin-right: 0;
}

.pagination .nav-links > *.next,
.comments-pagination .nav-links > *.next {
	margin-left: auto;
}

.pagination .nav-links > *.prev,
.comments-pagination .nav-links > *.prev {
	margin-right: auto;
}
@media only screen and (max-width: 821px) {

	.pagination .nav-links,
	.comments-pagination .nav-links {
		display: flex;
		flex-wrap: wrap;
	}

	.pagination .page-numbers,
	.comments-pagination .page-numbers {
		display: none;
	}

	.pagination .page-numbers.prev,
	.pagination .page-numbers.next,
	.comments-pagination .page-numbers.prev,
	.comments-pagination .page-numbers.next {
		display: inline-block;
		flex: 0 1 auto;
	}
}
@media only screen and (max-width: 481px) {

	.pagination .nav-short,
	.comments-pagination .nav-short {
		display: none;
	}
}

.comments-pagination {
	padding-top: calc(0.66 * var(--global--spacing-vertical));
	margin: calc(3 * var(--global--spacing-vertical)) auto;
}
@media only screen and (min-width: 822px) {

	.comments-pagination {
		margin: calc(3 * var(--global--spacing-vertical)) auto calc(4 * var(--global--spacing-vertical)) auto;
	}
}

.comments-pagination .nav-links > * {
	font-size: var(--global--font-size-md);
}

.widget-area {
	margin-top: calc(6 * var(--global--spacing-vertical));
	padding-bottom: calc(var(--global--spacing-vertical) / 3);
	color: var(--footer--color-text);
	font-size: var(--footer--font-size);
	font-family: var(--footer--font-family);
}
@media only screen and (min-width: 652px) {

	.widget-area {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: calc(2 * var(--global--spacing-horizontal));
	}
}
@media only screen and (min-width: 1024px) {

	.widget-area {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media only screen and (max-width: 481px) {

	.widget-area {
		margin-top: calc(3 * var(--global--spacing-vertical));
	}
}

.widget-area .wp-block-social-links.alignright {
	margin-top: var(--global--spacing-vertical);
	justify-content: flex-end;
}

.widget-area .wp-block-social-links.alignleft {
	margin-top: var(--global--spacing-vertical);
}

.widget-area:after {
	content: "";
	display: table;
	clear: both;
}

.widget h1,
.widget h2,
.widget h3,
.widget h4,
.widget h5,
.widget h6 {
	font-weight: var(--widget--font-weight-title);
	line-height: var(--widget--line-height-title);
}

.widget h1 {
	font-size: var(--global--font-size-md);
}

.widget h2 {
	font-size: var(--global--font-size-sm);
}

.widget h3 {
	font-size: var(--global--font-size-xs);
}

.widget h4 {
	font-size: var(--global--font-size-xs);
}

.widget h5 {
	font-size: var(--global--font-size-xs);
}

.widget h6 {
	font-size: var(--global--font-size-xs);
}

.widget ul {
	list-style-type: none;
	padding: 0;
}

.widget ul li {
	line-height: var(--widget--line-height-list);
}

.widget ul.sub-menu,
.widget ul.children {
	margin-left: var(--widget--spacing-menu);
}

.widget ul .sub-menu-toggle {
	display: none;
}

.widget a {
	color: var(--footer--color-link);
	text-decoration: underline;
	text-decoration-style: solid;
	text-decoration-color: currentColor;
}

.widget a:link,
.widget a:visited,
.widget a:active {
	color: var(--footer--color-link);
}

.widget a:hover {
	color: var(--footer--color-link-hover);
	text-decoration-style: dotted;
}

.search-form {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	max-width: var(--responsive--aligndefault-width);
}

.search-form > label {
	width: 100%;
	margin-bottom: 0;
	font-weight: var(--form--label-weight);
}

.search-form .search-field {
	flex-grow: 1;
	max-width: inherit;
	margin-top: calc(var(--global--spacing-vertical) / 3);
	margin-right: calc(0.66 * var(--global--spacing-horizontal));
}

.search-form .search-submit {
	margin-top: calc(var(--global--spacing-vertical) / 3);
	margin-left: 10px;
}

.widget_search > .search-form .search-field {
	margin-right: calc(-1 * var(--button--border-width));
	appearance: none;
	margin-bottom: calc(0.5 * var(--global--spacing-vertical));
}

.widget_search > .search-form .search-submit {
	margin-left: 0;
	margin-bottom: calc(0.5 * var(--global--spacing-vertical));
}

.widget_rss a.rsswidget .rss-widget-icon {
	display: none;
}

/* Category 07 is for any utility classes that are not assigned to a specific component. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
	word-break: normal;
}

.skip-link:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	-webkit-clip-path: none;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 0.875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

.has-black-color[class] {
	color: var(--global--color-black);
}

.has-black-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-black, #000);
	color: var(--local--color-primary);
}

.has-gray-color[class] {
	color: var(--global--color-gray);
}

.has-gray-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-gray, #000);
	color: var(--local--color-primary);
}

.has-dark-gray-color[class] {
	color: var(--global--color-dark-gray);
}

.has-dark-gray-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-dark-gray, #000);
	color: var(--local--color-primary);
}

.has-green-color[class] {
	color: var(--global--color-green);
}

.has-green-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-green, #fff);
	color: var(--local--color-primary);
}

.has-blue-color[class] {
	color: var(--global--color-blue);
}

.has-blue-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-blue, #fff);
	color: var(--local--color-primary);
}

.has-purple-color[class] {
	color: var(--global--color-purple);
}

.has-purple-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-purple, #fff);
	color: var(--local--color-primary);
}

.has-red-color[class] {
	color: var(--global--color-red);
}

.has-red-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-red, #fff);
	color: var(--local--color-primary);
}

.has-orange-color[class] {
	color: var(--global--color-orange);
}

.has-orange-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-orange, #fff);
	color: var(--local--color-primary);
}

.has-yellow-color[class] {
	color: var(--global--color-yellow);
}

.has-yellow-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-yellow, #fff);
	color: var(--local--color-primary);
}

.has-white-color[class] {
	color: var(--global--color-white);
}

.has-white-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-white, #fff);
	color: var(--local--color-primary);
}

.has-background a,
.has-background p,
.has-background h1,
.has-background h2,
.has-background h3,
.has-background h4,
.has-background h5,
.has-background h6 {
	color: currentColor;
}

.has-black-background-color[class] {
	background-color: var(--global--color-black);
}

.has-black-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-black, #000);
	background-color: var(--local--color-background);
}

.has-dark-gray-background-color[class] {
	background-color: var(--global--color-dark-gray);
}

.has-dark-gray-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-dark-gray, #000);
	background-color: var(--local--color-background);
}

.has-gray-background-color[class] {
	background-color: var(--global--color-gray);
}

.has-gray-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-gray, #000);
	background-color: var(--local--color-background);
}

.has-light-gray-background-color[class] {
	background-color: var(--global--color-light-gray);
}

.has-light-gray-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-light-gray, #fff);
	background-color: var(--local--color-background);
}

.has-green-background-color[class] {
	background-color: var(--global--color-green);
}

.has-green-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-green, #fff);
	background-color: var(--local--color-background);
}

.has-blue-background-color[class] {
	background-color: var(--global--color-blue);
}

.has-blue-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-blue, #fff);
	background-color: var(--local--color-background);
}

.has-purple-background-color[class] {
	background-color: var(--global--color-purple);
}

.has-purple-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-purple, #fff);
	background-color: var(--local--color-background);
}

.has-red-background-color[class] {
	background-color: var(--global--color-red);
}

.has-red-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-red, #fff);
	background-color: var(--local--color-background);
}

.has-orange-background-color[class] {
	background-color: var(--global--color-orange);
}

.has-orange-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-orange, #fff);
	background-color: var(--local--color-background);
}

.has-yellow-background-color[class] {
	background-color: var(--global--color-yellow);
}

.has-yellow-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-yellow, #fff);
	background-color: var(--local--color-background);
}

.has-white-background-color[class] {
	background-color: var(--global--color-white);
}

.has-white-background-color[class] > [class*=__inner-container] {
	--local--color-background: var(--global--color-white, #fff);
	background-color: var(--local--color-background);
}

.has-background:not(.has-text-color).has-black-background-color[class],
.has-background:not(.has-text-color).has-gray-background-color[class],
.has-background:not(.has-text-color).has-dark-gray-background-color[class] {
	color: var(--global--color-white);
}

.has-background:not(.has-text-color).has-black-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-gray-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-dark-gray-background-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-background, #fff);
	color: var(--local--color-primary, var(--global--color-primary));
}

.is-dark-theme .has-background:not(.has-text-color).has-black-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-gray-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-dark-gray-background-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-primary, #000);
}

.has-background:not(.has-text-color).has-green-background-color[class],
.has-background:not(.has-text-color).has-blue-background-color[class],
.has-background:not(.has-text-color).has-purple-background-color[class],
.has-background:not(.has-text-color).has-red-background-color[class],
.has-background:not(.has-text-color).has-orange-background-color[class],
.has-background:not(.has-text-color).has-yellow-background-color[class],
.has-background:not(.has-text-color).has-white-background-color[class] {
	color: var(--global--color-dark-gray);
}

.has-background:not(.has-text-color).has-green-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-blue-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-purple-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-red-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-orange-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-yellow-background-color[class] > [class*=__inner-container],
.has-background:not(.has-text-color).has-white-background-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-primary, #000);
	color: var(--local--color-primary, var(--global--color-primary));
}

.is-dark-theme .has-background:not(.has-text-color).has-green-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-blue-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-purple-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-red-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-orange-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-yellow-background-color[class] > [class*=__inner-container],
.is-dark-theme .has-background:not(.has-text-color).has-white-background-color[class] > [class*=__inner-container] {
	--local--color-primary: var(--global--color-background, #fff);
}

.has-purple-to-yellow-gradient-background {
	background: linear-gradient(160deg, var(--global--color-purple), var(--global--color-yellow));
}

.has-yellow-to-purple-gradient-background {
	background: linear-gradient(160deg, var(--global--color-yellow), var(--global--color-purple));
}

.has-green-to-yellow-gradient-background {
	background: linear-gradient(160deg, var(--global--color-green), var(--global--color-yellow));
}

.has-yellow-to-green-gradient-background {
	background: linear-gradient(160deg, var(--global--color-yellow), var(--global--color-green));
}

.has-red-to-yellow-gradient-background {
	background: linear-gradient(160deg, var(--global--color-red), var(--global--color-yellow));
}

.has-yellow-to-red-gradient-background {
	background: linear-gradient(160deg, var(--global--color-yellow), var(--global--color-red));
}

.has-purple-to-red-gradient-background {
	background: linear-gradient(160deg, var(--global--color-purple), var(--global--color-red));
}

.has-red-to-purple-gradient-background {
	background: linear-gradient(160deg, var(--global--color-red), var(--global--color-purple));
}
