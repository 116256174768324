// Extra Large = 1440px
@mixin extraLarge {
  @media (max-width: ($width__extraLarge - 1)) {
    @content;
  }
}

@mixin minExtraLarge {
  @media (min-width: ($width__extraLarge)) {
    @content;
  }
}

// Larger = 1200px
@mixin larger {
  @media (max-width: ($width__larger - 1)) {
    @content;
  }
}

@mixin minLarger {
  @media (min-width: ($width__larger)) {
    @content;
  }
}

// Large = 1024px
@mixin large {
  @media (max-width: ($width__large - 1)) {
    @content;
  }
}

@mixin minLarge {
  @media (min-width: ($width__large)) {
    @content;
  }
}

// Medium = 768px
@mixin medium {
  @media (max-width: ($width__medium - 1)) {
    @content;
  }
}

@mixin minMedium {
  @media (min-width: ($width__medium)) {
    @content;
  }
}

// Small = 576px
@mixin small {
  @media (max-width: ($width__small - 1)) {
    @content;
  }
}

@mixin minSmall {
  @media (min-width: ($width__small)) {
    @content;
  }
}

// Extrasmall = 375px
@mixin extraSmall {
  @media (max-width: ($width__extraSmall - 1)) {
    @content;
  }
}



@function rem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}



@mixin buttonColor($color) {
  .button {
    &--#{$color} {
      color: $color;
      border: 1px solid white;

      .button__text {
        color: color;
      }

      .button__arrow {
        path {
          stroke: color;
        }
      }
    }
  }
}

@mixin resetSummary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: rem(28);

  > * {
    color: $color_main__text;
    text-decoration: none;
    font-weight: normal;
  }
}
@mixin resetSummarySmall {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: rem(25);

  > * {
    color: $color_main__text;
    text-decoration: none;
    font-weight: normal;
  }
}