@font-face {
  font-family: GothamRoundedBold;
  src: url(../assets/fonts/gotham-rounded/GothamRounded-Bold.otf)
    format("opentype");
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: GothamRoundedBold;
  src: url(../assets/fonts/gotham-rounded/GothamRounded-Medium.otf)
    format("opentype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter/static/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter/static/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: medium;
}
@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter/static/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: semibold;
}
@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter/static/Inter-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: light;
}

$grid__gap: rem(24);

$padding__column: rem(16);
$padding__column--x2: calc(#{$padding__column} * 2);

$padding__column--large: rem(24);
$padding__column--larger: rem(32);

$row__gap--larger: 3rem;
$row__gap--small: 2rem;

$width__extraLarge: 1440px;
$width__larger: 1200px;
$width__large: 1024px;
$width__medium: 768px;
$width__small: 576px;
$width__extraSmall: 375px;

$font_size__desktop: 16px;
$font_size__tablet: 14px;
$font_size__mobile: 14px;
$font_size__small: $font_size__mobile;

$font_family__GotamRoundedBold: GothamRoundedBold;
$font_family__Inter: Inter;

$color_main__text: #031a30;
$color_secondary__text: #2f4356;

$color__primary: #0a61b4;
$color__secondary: #f0f5fa;
$color__success: #20976c;
$color__error: #d00000;

$color__border: #baccde;
$color__footer: #f0f8ff;

$color__grey: #F6F6F6;
$color__light-blue: #E1EDF9;

$color__dark-green: #023c39;

$color__progress-background: #cbd4dc;

$color__gray-blue: #f0f5fa;

$color_main__blue: $color__primary;

$color_main__british-racing-green: #024127;
$color_main__acid-green: #a8ca13;
$color_main__dark-gunmetal: #212529;
$color_main__dark-gunmetal--opacity-05: hsla(210, 11%, 15%, 0.5);
$color_main__dark-gunmetal--opacity-01: hsla(210, 11%, 15%, 0.1);
$color_main__green-05: hsla(158, 65%, 36%, 0.75);
$color_main__boston-university-red: #d00000;
$color_main__medium-electric-blue: #084f94;

$color_one__aero-blue: #cefdea;
$color_one__light-godenrod-yellow: #f3fbd0;
$color_one__platinum: #e3e6e8;
$color_one__selective-yellow: #ffba08;
$color_one__water: #cfe6fc;

$layer__image: 1100;
$layer__neutral: 1000;

$border__color: #BACCDE;